import types from "./types";

const signInStart = () => {
  return {
    type: types.USER_SIGN_IN_START,
  };
};

const signIn = response => {
  return {
    type: types.USER_SIGN_IN,
    payload: response,
  };
};

const signInError = err => {
  return {
    type: types.USER_SIGN_IN_ERROR,
    payload: err,
  };
};

const signOut = response => {
  return {
    type: types.USER_SIGN_OUT,
    payload: response,
  };
};

const validateSession = sessionValid => {
  return {
    type: types.USER_VALIDATE_SESSION,
    payload: sessionValid,
  };
};

const getAuthenticationServerUrl = response => {
  return {
    type: types.USER_GET_AUTHENTICATION_SERVER_URL,
    payload: response,
  };
};

export default {
  signInStart,
  signIn,
  signInError,
  signOut,
  validateSession,
  getAuthenticationServerUrl,
};
