import moment from "moment";

import React, { Component } from "react";
import { debriefOperations } from "./../../../../redux/debrief/";
import { withRouter } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";

import { ListItem, ListItemColumn, ListItemMeetingDate } from "./style.js";

class DebriefListItem extends Component {
  constructor(props) {
    super(props);
    this.archive = this.archive.bind(this);
    this.goToDebrief = this.goToDebrief.bind(this);
  }

  goToDebrief() {
    const { history, debriefLocation, debrief } = this.props;
    const stageIndexToStage = ["prepare", "record", "review", "plan", "action"];
    const stageIndex = debrief.completed_stages.indexOf(false);
    let stage = "action";
    if (stageIndex > -1) {
      stage = stageIndexToStage[stageIndex];
    }
    const newDestination = `/l/${debriefLocation.id}/d/${debrief.id}/${stage}`;
    history.push(newDestination);
  }

  archive(e) {
    e.stopPropagation();
    const { deleteDebrief, debrief } = this.props;
    deleteDebrief(debrief);
  }

  render() {
    const { debrief, displayDelete } = this.props;

    return (
      <ListItem onClick={this.goToDebrief}>
        {displayDelete && (
          <ListItemColumn className="archive">
            <IconButton onClick={this.archive}>
              <DeleteIcon />
            </IconButton>
          </ListItemColumn>
        )}
        {debrief.meeting.shift ? (
          <ListItemColumn className="left">{debrief.meeting.shift}</ListItemColumn>
        ) : (
          <ListItemColumn className="left empty">Not Provided</ListItemColumn>
        )}
        <ListItemColumn className="check">{debrief.completed_stages[0] && <CheckIcon />}</ListItemColumn>
        <ListItemColumn className="check">{debrief.completed_stages[1] && <CheckIcon />}</ListItemColumn>
        <ListItemColumn className="check">{debrief.completed_stages[2] && <CheckIcon />}</ListItemColumn>
        <ListItemColumn className="check">{debrief.completed_stages[3] && <CheckIcon />}</ListItemColumn>
        <ListItemColumn>
          {debrief.completed_stages[3] && `${debrief.take_action.progress}%`}
        </ListItemColumn>
        <ListItemColumn className="chevron right">
          <ChevronRightIcon />
        </ListItemColumn>
      </ListItem>
    );
  }
}

export default withRouter(DebriefListItem);
