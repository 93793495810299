import types from './types';

const loadAll = () => {
  return {
    type: types.LOAD_ALL,
    payload: {
      isLoading: true,
    }
  }
}

const setAll = (locations) => {
  return {
    type: types.SET_ALL,
    payload: {
      locations,
      isLoaded: true,
      isLoading: false,
    }
  }
};

const setCurrentId = (currentLocationId) => {
  return {
    type: types.SET_CURRENT_ID,
    payload: {
      currentLocationId,
    }
  }
}

export default {
  loadAll,
  setAll,
  setCurrentId,
};
