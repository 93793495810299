import _ from "underscore";
import types from "./types";

const defaultValues = {
  sortedDebriefAssignments: {
    facilitator: [],
    manager: [],
  },
  debriefAssignments: [],
  currentDebriefAssignment: {},
  currentDebriefAssignmentId: null,
  isLoading: false,
  isLoaded: false,
};

const getCurrentAssignmentDebrief = (debriefAssignments, currentDebriefAssignmentId) => {
  return _.find(debriefAssignments, da => da.id === currentDebriefAssignmentId);
};

export default function reducer(state = defaultValues, action) {
  switch (action.type) {
    case types.LOAD_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.SET_CURRENT_ID: {
      const currentDebriefAssignment = getCurrentAssignmentDebrief(
        state.debriefAssignments,
        action.payload.currentDebriefAssignmentId
      );

      return {
        ...state,
        ...action.payload,
        currentDebriefAssignment: currentDebriefAssignment,
      };
    }
    case types.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.CLEAR: {
      return {
        ...defaultValues,
      };
    }
    default: {
      return state;
    }
  }
}
