import styled from "styled-components";

export const Wrapper = styled.div`
  h1 {
    margin-bottom: 10px;
  }

  .grid {
    margin-top: 10px;
  }

  .subtitle {
    color: ${props => props.theme.greyDark};
    font-weight: 300;
    letter-spacing: 0.3px;
  }

  .label {
    font-size: 13px;
    margin-bottom: 0;
    color: ${props => props.theme.greyDark};
    letter-spacing: 0.5px;
  }

  .blank {
    color: ${props => props.theme.greyXLight};
    font-weight: bold;
  }

  .input {
    margin-bottom: 21px;
  }

  ul.bullet {
    li {
      font-size: 15px;
      margin-top: 21px;
      list-style: none;
      list-style-position: outside;

      &:before {
        content: "•";
        font-size: 16px;
        color: ${props => props.theme.secondary};
        position: absolute;
        left: 40px;
      }
    }
  }
`;
