import logo from "./../../assets/imgs/icons/debriefer-icon.svg";
import React, { Component } from "react";
import { Style } from "./style.js";

class PageContentWrapper extends Component {
  render() {
    return <Style className="page-content-wrapper">{this.props.children}</Style>;
  }
}

export default PageContentWrapper;
