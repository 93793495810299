import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { userOperations } from "./../redux/user";

import { Dimmer, Loader } from "semantic-ui-react";
import Debrief from "./../pages/Debrief/";
import DebriefAssignments from "./../pages/DebriefAssignments/";
import SignIn from "./../pages/SignIn";

class MainRouter extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userOperations.validateSession());
    if (!props.user.authenticationServerUrl) {
      this.props.dispatch(userOperations.getAuthenticationServerUrl());
    }
  }

  dataLoaded() {
    return (
      this.props.debriefAssignmentLoaded &&
      this.props.locationLoaded &&
      this.props.debriefLoaded
    );
  }

  render() {
    const { user } = this.props;

    if (!user.isLoggedIn) {
      if (user.sessionChecked) {
        const referrer = user.justSignedOut ? "" : `referrer=${window.location.pathname}`;
        const search = window.location.search ? `${window.location.search}&${referrer}` : `?${referrer}`;
        return (
          <BrowserRouter>
            <Switch>
              <Route exact path="/sign-in" component={SignIn} />
              <Redirect
                to={{
                  pathname: "/sign-in",
                  search,
                }}
              />
            </Switch>
          </BrowserRouter>
        );
      } else {
        return (
          <Dimmer active>
            <Loader />
          </Dimmer>
        );
      }
    }
    const params = new URLSearchParams(window.location.search);
    const email = params.email;
    if (email && user.username !== email) {
      this.props.dispatch(userOperations.signOut());
    }

    if (this.dataLoaded()) {
      return (
        <div>
          <BrowserRouter>
            <Switch>
              <Route exact path="/sign-in" component={SignIn} />
              <Route exact path="/" component={DebriefAssignments} />
              <Route exact path="/l/:locationId/d/:debriefId" component={Debrief} />
              <Route exact path="/l/:locationId/d/:debriefId/:stage" component={Debrief} />
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </div>
      );
    } else {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    }
  }
}

export default connect(store => {
  return {
    user: store.user,
    debriefAssignmentLoaded: store.debriefAssignment.isLoaded,
    locationLoaded: store.location.isLoaded,
    debriefLoaded: store.debrief.isLoaded,
  };
})(MainRouter);
