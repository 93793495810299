import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyledReactModal } from './style.js';

class Modal extends Component {
  render() {
    const ModalSubComponent = this.props.modal.class;
    const modalSubProps     = {dispatch: this.props.dispatch, ...this.props.modal.props};

    if(this.props.modal.isOpen) {
      return (
        <StyledReactModal isOpen={this.props.modal.isOpen}>
          <ModalSubComponent {...modalSubProps}/>
        </StyledReactModal>
      );
    } else {
      return null;
    }
  }
}

export default connect((store) => {
  return {
    modal: store.modal,
  }
})(Modal);;
