import types from './types';

const loadAll = () => {
  return {
    type: types.LOAD_ALL,
    payload: {
      isLoading: true,
    }
  }
}

const setAll = (themes) => {
  return {
    type: types.SET_ALL,
    payload: {
      themes,
      isLoaded: true,
      isLoading: false,
    }
  }
};

export default {
  loadAll,
  setAll,
};
