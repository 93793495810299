import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

class Input extends Component {
  render() {
    const { id, name } = this.props;
    return <TextField {...this.props} id={id || name} name={name || id} className="input" />;
  }
}

export default Input;
