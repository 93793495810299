import React, { Component } from "react";
import { debriefOperations } from "./../../redux/debrief/";
import Input from "./../Input";

class FormAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.currentForm,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let change = {};
    change[event.target.name] = event.target.value;

    // Make sure component state has fully updates before dispatching redux update
    this.setState(change, () => {
      this.props.dispatch(debriefOperations.updateAction(this.state));
    });
  }

  render() {
    const { formDirty } = this.props;
    return (
      <form>
        <h3>Our Aim</h3>

        <Input
          id="aim"
          placeholder="Our aim is to..."
          helperText="Enter an opportunity for improvement you will focus on"
          type="text"
          onChange={this.handleChange}
          onBlur={this.props.save}
          value={this.state.aim}
          error={!this.state.aim && formDirty}
          fullWidth
          multiline
        />

        <Input
          id="target"
          placeholder="Our target is ..."
          helperText="Enter what your target goal will be for your aim"
          type="text"
          onChange={this.handleChange}
          onBlur={this.props.save}
          value={this.state.target}
          error={!this.state.target && formDirty}
          fullWidth
          multiline
        />

        <h3>Our Action Plan</h3>

        <Input
          id="measure"
          placeholder="We'll measure progress by..."
          helperText="How you will know you have reached your target goal"
          type="text"
          onChange={this.handleChange}
          onBlur={this.props.save}
          value={this.state.measure}
          error={!this.state.measure && formDirty}
          fullWidth
          multiline
        />

        <Input
          id="driveChange"
          placeholder="We can drive change by..."
          helperText="Something you will do to drive change"
          type="text"
          onChange={this.handleChange}
          onBlur={this.props.save}
          value={this.state.driveChange}
          error={!this.state.driveChange && formDirty}
          fullWidth
          multiline
        />

        <Input
          id="whoWhatWhen"
          placeholder="Who is doing what by when..."
          helperText="Who, what, when"
          type="text"
          onChange={this.handleChange}
          onBlur={this.props.save}
          value={this.state.whoWhatWhen}
          error={!this.state.whoWhatWhen && formDirty}
          fullWidth
          fullWidth
          multiline
        />
      </form>
    );
  }
}

export default FormAction;
