import './App.css';
import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import ReactDOM from 'react-dom';
import App from './modules/App.js';

ReactDOM.render(
	<App/>,
	document.getElementById('root')
);
