const LOAD_ALL =        "app/debrief/LOAD_ALL";
const SET_CURRENT_ID =  "app/debrief/SET_CURRENT_ID";
const UPDATE =          "app/debrief/UPDATE";
const ADD =             "app/debrief/ADD";
const SET_ALL =         "app/debrief/SET_ALL";
const CLEAR =         "app/debrief/CLEAR";

export default {
  LOAD_ALL,
  SET_CURRENT_ID,
  UPDATE,
  ADD,
  SET_ALL,
  CLEAR,
}
