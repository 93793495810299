import styled from "styled-components";
import { Link } from "react-router-dom";

export const ListItem = styled.tr`
  cursor: pointer;
  border-bottom: solid 1px ${props => props.theme.greyXXXLight};

  &:nth-child(even) {
    background: #fcfcfc;
  }
  &:hover {
    background: ${props => props.theme.xxxLight};
  }
`;

export const ListItemColumn = styled.td`
  text-align: ${props => (props.textAlign ? props.textAlign : "center")};
  padding: 28px 14px;
  color: ${props => props.theme.black};
  font-size: 16px;
  font-weight: 300;

  &.empty {
    color: ${props => props.theme.greyXXLight};
  }

  &.left {
    text-align: left;
    padding-left: 28px;
  }

  &.right {
    text-align: right;
    padding-right: 28px;
  }

  &:first-child {
    padding-left: 28px;
  }

  &:last-child {
    padding-right: 28px;
  }

  &.check {
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 0;
    svg {
      color: ${props => props.theme.primary};
      font-size: 36px;
    }
  }

  &.chevron {
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 0;
    svg {
      color: ${props => props.theme.grey};
      font-size: 32px;
    }
  }

  &.archive {
    font-size: 18px;
    padding: 10px;

    button {
      transition-property: all;
      color: ${props => props.theme.red};
      svg {
        color: inherit;
      }

      &:hover {
        color: ${props => props.theme.redDark};
      }
    }
  }
`;

export const ListItemMeetingDate = styled.span`
  padding-right: 1rem;
`;

export const PlanLink = styled(Link)`
  margin-right: 1rem;
`;
