import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.25,
    color: grey[500],
    fontFamily: "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  white: {
    color: "rgba(255, 255, 255, 0.6)",
  },
}));

function TypographyParagraph(props) {
  const { className, customColor, ...other } = props;
  const classes = useStyles();

  return <p className={clsx(classes.root, { [classes.white]: customColor === "white" }, className)} {...other} />;
}

TypographyParagraph.propTypes = {
  /** This will change the typeface color from its default of grey */
  customColor: PropTypes.oneOf(["white"]),
  /** Optional prop for custom styling */
  className: PropTypes.string,
};

export default TypographyParagraph;
