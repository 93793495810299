import React, { Component } from "react";
import DebriefListItem from "./../DebriefListItem";
import DebriefNewListItem from "./../DebriefNewListItem";
import {
  Wrapper,
  ListItem,
  ListItemPrimary,
  DebriefsTableWrapper,
  DebriefsTable,
  DebriefsTableHead,
  DebriefsTableRow,
  DebriefsTableHeader,
} from "./style.js";

class DebriefAssignmentListItem extends Component {
  constructor(props) {
    super(props);
    this.buildDebriefTable = this.buildDebriefTable.bind(this);
    this.sortedDebriefs = this.sortedDebriefs.bind(this);
  }

  sortedDebriefs() {
    const { debriefs } = this.props;

    return debriefs.sort(function(a, b) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
  }

  buildDebriefTable() {
    const { location, debriefAssignment, debriefs, dispatch, displayDelete, deleteDebrief } = this.props;

    if (debriefs.length === 0) {
      return null;
    }

    return (
      <DebriefsTable>
        <DebriefsTableHead>
          <DebriefsTableRow>
            {displayDelete && <DebriefsTableHeader className="archive" />}
            <DebriefsTableHeader className="left">Shift</DebriefsTableHeader>
            <DebriefsTableHeader>Prepare</DebriefsTableHeader>
            <DebriefsTableHeader>Record</DebriefsTableHeader>
            <DebriefsTableHeader>Review</DebriefsTableHeader>
            <DebriefsTableHeader>Action Plan</DebriefsTableHeader>
            <DebriefsTableHeader>Take Action</DebriefsTableHeader>
            <DebriefsTableHeader />
          </DebriefsTableRow>
        </DebriefsTableHead>
        <tbody>
          {this.sortedDebriefs().map(debrief => (
            <DebriefListItem
              key={"da-" + debriefAssignment.id + "-d-" + debrief.id}
              dispatch={dispatch}
              debriefLocation={location}
              debriefAssignment={debriefAssignment}
              debrief={debrief}
              displayDelete={displayDelete}
              deleteDebrief={deleteDebrief}
            />
          ))}
        </tbody>
      </DebriefsTable>
    );
  }

  render() {
    const { location, debriefAssignment, dispatch, debriefs } = this.props;

    return (
      <Wrapper>
        <ListItem>
          {/* {debriefAssignment.role === "facilitator" && <ListItemPrimary>{location.facility}</ListItemPrimary>} */}
          <ListItemPrimary>{location.facility ? `${location.facility} - ${location.unit}` : location.unit}</ListItemPrimary>
        </ListItem>

        {debriefs.length > 0 ? (
          <div>
            <DebriefsTableWrapper>{this.buildDebriefTable()}</DebriefsTableWrapper>
            <DebriefNewListItem dispatch={dispatch} location={location} />
          </div>
        ) : (
          <div className="empty-list">
            <p>No debriefs have been started for this work setting.</p>
            <DebriefNewListItem dispatch={dispatch} location={location} />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default DebriefAssignmentListItem;
