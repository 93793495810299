import logo from "./../../assets/imgs/icons/debriefer-icon.svg";
import React, { Component } from "react";
import { PanelStyle } from "./style.js";

class Panel extends Component {
  render() {
    return <PanelStyle className="panel">{this.props.children}</PanelStyle>;
  }
}

export default Panel;
