import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import FormTakeAction from "./../../../../components/FormTakeAction";

import { Wrapper } from "./style.js";

class DebriefAction extends Component {
  render() {
    const { debrief, formDirty } = this.props;
    return (
      <Wrapper>
        <h1>Action Plan Progress</h1>
        <Grid columns="3">
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="subtitle">Update your progress as you execute your action plan:</p>
              <p className="label">Our aim</p>
              {debrief.action.aim ? <p>{debrief.action.aim}</p> : <p className="blank">--</p>}
              <p className="label">Our target</p>
              {debrief.action.target ? <p>{debrief.action.target}</p> : <p className="blank">--</p>}
              <p className="label">How we will measure progress</p>
              {debrief.action.measure ? <p>{debrief.action.measure}</p> : <p className="blank">--</p>}
              <p className="label">How we will drive change</p>
              {debrief.action.driveChange ? <p>{debrief.action.driveChange}</p> : <p className="blank">--</p>}
              <p className="label">Who, what, when</p>
              {debrief.action.whoWhatWhen ? <p>{debrief.action.whoWhatWhen}</p> : <p className="blank">--</p>}
            </Grid.Column>
            <Grid.Column width={10}>
              <FormTakeAction
                dispatch={this.props.dispatch}
                currentForm={this.props.debrief.take_action}
                save={this.props.save}
                formDirty={formDirty}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Wrapper>
    );
  }
}

export default DebriefAction;
