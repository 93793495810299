import React, { Component } from "react";
import { Circle } from "./style.js";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class NumberCircle extends Component {
  render() {
    const { active, complete, number } = this.props;
    if (complete) {
      return <CheckCircleIcon style={{ fontSize: 30 }} />;
    } else {
      return <Circle className={`number-circle ${active ? "active" : ""}`}>{number}</Circle>;
    }
  }
}

export default NumberCircle;
