import styled from "styled-components";

export const Form = styled.form`
  .value {
    display: inline-block;
    margin-left: 14px;
    font-size: 18px;
    font-weight: bold;
  }

  .rangeslider__handle {
    outline: none;
    &:focus {
      background: ${props => props.theme.xxLight};
    }
  }
`;
