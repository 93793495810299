import types from './types';

const set = (ModalClass, modalProps) => {
  return {
    type: types.SET,
    payload: {
      class: ModalClass,
      props: modalProps,
      isOpen: true,
    },
  }
};

const unset = () => {
  return {
    type: types.UNSET,
    payload: {
      class: null,
      props: {},
      isOpen: false,
    },
  }
};

export default {
  set, 
  unset 
};
