import React, { Component } from "react";
import { connect } from "react-redux";
import { debriefOperations } from "./../../redux/debrief/";

import PageContentWrapper from "./../../components/PageContentWrapper";
import Header from "./../../components/Header";
import SectionHeader from "./../../components/SectionHeader";
import DebriefAssignmentList from "./components/DebriefAssignmentList";
import DebriefAssignmentEmptyState from "./components/DebriefAssignmentEmptyState.jsx";
//import DebriefAssignmentsFilter from "./components/DebriefAssignmentsFilter";
import { Wrapper, DebriefAssignmentsWrapper, DebriefAssignmentsContent } from "./style.js";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Clear";

class DebriefAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDelete: false,
      snackbarOpen: false,
      deletedDebrief: null,
    };
    //this.filteredDebriefAssignments = this.filteredDebriefAssignments.bind(this);
    this.toggleDisplayDelete = this.toggleDisplayDelete.bind(this);
    this.deleteDebrief = this.deleteDebrief.bind(this);
    this.undoDelete = this.undoDelete.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  }

  toggleDisplayDelete() {
    this.setState({ displayDelete: !this.state.displayDelete });
  }

  deleteDebrief(debrief) {
    this.setState({
      snackbarOpen: true,
      deletedDebrief: debrief,
    });
    this.props.dispatch(debriefOperations.updateStatus(debrief, "archived"));
  }

  undoDelete() {
    const { deletedDebrief } = this.state;
    this.setState({ snackbarOpen: false });
    this.props.dispatch(debriefOperations.updateStatus(deletedDebrief, "active"));
  }

  handleSnackbarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  }

  render() {
    const { locations, sortedDebriefAssignments, debriefAssignments, debriefs, dispatch } = this.props;
    const { displayDelete } = this.state;

    const managerAssignments = sortedDebriefAssignments.manager; // this.filteredDebriefAssignments(sortedDebriefAssignments, "manager");
    const facilitatorAssignments = sortedDebriefAssignments.facilitator; //this.filteredDebriefAssignments(sortedDebriefAssignments, "facilitator");
    return (
      <Wrapper>
        <PageContentWrapper>
          <Header dispatch={dispatch} />
          <div className="scrollable">
            <SectionHeader title="Your Work Settings" subtitle="Share Survey Results and Follow-Through" />

            <DebriefAssignmentsWrapper>

              <DebriefAssignmentsContent>
                {managerAssignments.length > 0 && (
                  <DebriefAssignmentList
                    title="You Manage"
                    dispatch={dispatch}
                    locations={locations}
                    debriefs={debriefs}
                    sortedDebriefAssignments={managerAssignments}
                    allAssignments={debriefAssignments}
                    displayDelete={displayDelete}
                    toggleDisplayDelete={this.toggleDisplayDelete}
                    deleteDebrief={this.deleteDebrief}
                  />
                )}
                {facilitatorAssignments.length > 0 && (
                  <DebriefAssignmentList
                    title="You're Facilitating"
                    dispatch={this.props.dispatch}
                    locations={locations}
                    debriefs={debriefs}
                    sortedDebriefAssignments={facilitatorAssignments}
                    allAssignments={debriefAssignments}
                    displayDelete={displayDelete}
                    toggleDisplayDelete={this.toggleDisplayDelete}
                    deleteDebrief={this.deleteDebrief}
                  />
                )}
                {managerAssignments.length < 1 && facilitatorAssignments.length < 1 && <DebriefAssignmentEmptyState />}
              </DebriefAssignmentsContent>
            </DebriefAssignmentsWrapper>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Debrief deleted</span>}
            className="snackbar"
            action={[
              <Button key="undo" color="secondary" size="small" onClick={this.undoDelete}>
                UNDO
              </Button>,
              <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </PageContentWrapper>
      </Wrapper>
    );
  }
}

export default connect(store => {
  return {
    user: store.user,
    debriefs: store.debrief.debriefs,
    locations: store.location.locations,
    debriefAssignments: store.debriefAssignment.debriefAssignments,
    sortedDebriefAssignments: store.debriefAssignment.sortedDebriefAssignments,
  };
})(DebriefAssignments);
