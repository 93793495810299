import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import FormAction from "./../../../../components/FormAction";

import { Wrapper } from "./style.js";

class DebriefPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      showDelete: false,
    };
    this.getItem = this.getItem.bind(this);
  }

  getItem(item, i, group) {
    return <li key={`${group}-${i}`}>{item}</li>;
  }

  render() {
    const { debrief, formDirty } = this.props;
    return (
      <Wrapper>
        <h1>Develop an Action Plan</h1>
        <Grid columns="3">
          <Grid.Row>
            <Grid.Column width={6}>
              <p className="subtitle">
                As a team, select an opportunity for improvement. We suggest starting with the areas you felt your team
                could tackle locally. The following are some of the items you entered in the previous step.
              </p>
              <ul className="bullet">
                {debrief.custom_review_items.you.map((item, i) => this.getItem(item, i, "you"))}
                {debrief.custom_review_items.team.map((item, i) => this.getItem(item, i, "team"))}
                {debrief.custom_review_items.needAssistance.map((item, i) => this.getItem(item, i, "needAssistance"))}
              </ul>
            </Grid.Column>
            <Grid.Column width={10}>
              <FormAction
                dispatch={this.props.dispatch}
                currentForm={this.props.debrief.action}
                save={this.props.save}
                formDirty={formDirty}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Wrapper>
    );
  }
}

export default DebriefPlan;
