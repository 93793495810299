import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./../config/store.js";

import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme, muiTheme } from "./theme.js";

import MainRouter from "./MainRouter";
import Modal from "./../components/Modal";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <MainRouter />
            <Modal />
          </MuiThemeProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
