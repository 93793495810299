import _ from "underscore";
import actions from "./actions";
import { userIdOverride } from "./../../modules/userOverride";

const sortAll = data => {
  return {
    facilitator: _.filter(data, da => da.role === "facilitator"),
    manager: _.filter(data, da => da.role === "manager"),
  };
};

const loadAll = force => (dispatch, getState) => {
  const debriefAssignment = getState().debriefAssignment;
  if (!force && (debriefAssignment.isLoading || debriefAssignment.isLoaded)) {
    return;
  }

  dispatch(actions.clear());
  dispatch(actions.loadAll());

  fetch(`/api/debrief-assignments?userId=${userIdOverride}`, {
    credentials: "include",
    headers: { Pragma: "no-cache", "Cache-Control":"no-cache" },
  })
    .then(response => {
      if (response.status === 401) {
        throw Error("Unauthorized");
      }
      return response.json();
    })
    .then(data => {
      const sorted = sortAll(data);
      dispatch(
        actions.setAll({
          sortedDebriefAssignments: sorted,
          debriefAssignments: data,
        })
      );
    })
    .catch(e => {
      console.error("Parsing debriefs failed", e);
    });
};

const setCurrentId = Id => (dispatch, getState) => {
  dispatch(actions.setCurrentId(Id));
};

export default {
  loadAll,
  setCurrentId,
};
