import types from './types';

const defaultValues = {
  class: null,
  props: {},
  isOpen: false,
};

export default function reducer(state=defaultValues, action) {
  switch (action.type) {
    case types.SET: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.UNSET: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      return state;
    }
  }
}
