import React, { Component } from "react";
import { debriefOperations } from "./../../redux/debrief";
import Input from "./../Input";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";

import { Form } from "./style";

class FormCustomItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newItem: "",
      show: props.data && Array.isArray(props.data) && props.data.length === 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.additem = this.additem.bind(this);
    this.blurred = this.blurred.bind(this);
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  submit(e) {
    e.preventDefault();

    if (this.state.newItem) {
      this.props.dispatch(debriefOperations.addCustomItem(this.state.newItem, this.props.group));
      this.setState({ newItem: "" }, () => this.inputText.focus());
    }
  }

  blurred() {
    if (this.state.newItem) {
      this.props.dispatch(debriefOperations.addCustomItem(this.state.newItem, this.props.group));
        this.setState({ newItem: "", show: false });
    }
  }


  additem(e) {
    this.setState({show:true}, () => this.inputText.focus());
  }

  render() {
    const { group } = this.props;
    const { show } = this.state;

    let label;
    let helperText;
    switch (group) {
      case "you":
        label = "Item I will tackle";
        helperText = "Something from the findings that I will tackle";
        break;
      case "team":
        label = "Item for our team";
        helperText = "Something from the findings that our team can tackle";
        break;
      case "needAssistance":
        label = "Item we need help with";
        helperText = "Item that we need help to tackle";
        break;
    }
    return (
      <Form className="form" onSubmit={this.submit}>

        {show && <TextField
          id="newItem"
          name="newItem"
          className="input"
          label={label}
          helperText={helperText}
          type="text"
          onChange={this.handleChange}
          onBlur={this.blurred}
          value={this.state.newItem}
          fullWidth
          multiline
          inputRef={(input) => this.inputText = input}
        />}
        <Button color="primary" type="submit" onClick={this.additem}>
          <Add/>Add Item
        </Button>
      </Form>
    );
  }
}

export default FormCustomItem;
