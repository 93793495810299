import moment from "moment";
import React, { Component } from "react";
import ReactDatePicker from "react-datepicker";
import Input from "./../Input";
import Label from "./../Label";
import { InputStyle } from "./style";

import "react-datepicker/dist/react-datepicker.css";

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRaw = this.handleChangeRaw.bind(this);
  }

  handleChange(date) {
    // Returning date this way so that we can use a generic form data handler
    const event = {
      target: {
        name: this.props.id,
        value: date,
        type: "date",
      },
    };

    this.props.onChange(event);
  }

  handleChangeRaw(value) {
    const date = moment(value);
    if (date) {
      this.handleChange(date);
    }
  }

  render() {
    const { showTimeSelect, value, minDate, disabled, placeholder, fullWidth, label, helperText, error } = this.props;

    return (
      <InputStyle>
        <ReactDatePicker
          showTimeSelect={!!showTimeSelect}
          onChange={this.handleChange}
          handleChangeRaw={this.handleChangeRaw}
          customInput={<Input label={label} helperText={helperText} fullWidth={fullWidth} error={error} />}
          selected={value ? new Date(value) : undefined}
          minDate={minDate}
          timeIntervals={5}
          disabled={disabled}
          dateFormat={"MMMM d, yyyy h:mm aa"}
          placeholderText={placeholder}
        />
      </InputStyle>
    );
  }
}

export default DatePicker;
