import types from "./types";

const loadAll = () => {
  return {
    type: types.LOAD_ALL,
    payload: {
      isLoading: true,
    },
  };
};

const setAll = payload => {
  return {
    type: types.SET_ALL,
    payload: {
      isLoaded: true,
      isLoading: false,
      ...payload,
    },
  };
};

const setCurrentId = currentDebriefAssignmentId => {
  return {
    type: types.SET_CURRENT_ID,
    payload: {
      currentDebriefAssignmentId,
    },
  };
};

const clear = () => {
  return {
    type: types.CLEAR,
  };
};

export default {
  loadAll,
  setAll,
  setCurrentId,
  clear,
};
