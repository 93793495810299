import React, { Component } from 'react';
import { LabelStyle } from './style.js';

class Label extends Component {
  render() {
    // Only create label if text gets passed into it
    return this.props.children ? (<LabelStyle>{ this.props.children }</LabelStyle>) : null;
  }
}

export default Label;
