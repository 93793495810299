import _ from "underscore";

import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import FormMeeting from "./../../../../components/FormMeeting";
import {findassignments} from "../../../../helpers";

import { Wrapper } from "./style.js";

class DebriefPrepare extends Component {
  render() {
    const { debriefAssignments, location, formDirty } = this.props;

    const {managerName,facilitatorName } = findassignments(debriefAssignments, location.id);

    return (
      <Wrapper>
        <h1>Prepare for the Debrief Meeting</h1>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={10}>
              <h3>Manager{managerName ? `: ${managerName}` : ""}</h3>
              <p>
                You'll schedule these sessions, as you know best how to fit them into your schedule. You may need to
                schedule a few to catch enough people. Encourage participation!
              </p>
              <h3>Facilitator{facilitatorName ? `: ${facilitatorName}` : ""}</h3>
              <p>
                A facilitator has been assigned to run the sessions. The Manager and Facilitator should touch base to:
              </p>
              <ul className="bullet">
                <li>Determine mutually agreeable time(s) for sessions</li>
                <li>
                  Review an agenda; ask if the manager has any questions or specific areas of interest they would like the
                  facilitator to cover.
                </li>
              </ul>
            </Grid.Column>
            <Grid.Column width={6}>
              <h3>Schedule the Meeting</h3>
              <FormMeeting
                dispatch={this.props.dispatch}
                formType="prepare"
                currentForm={this.props.debrief.meeting}
                save={this.props.save}
                formDirty={formDirty}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Wrapper>
    );
  }
}

export default DebriefPrepare;
