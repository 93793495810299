import styled from "styled-components";

export const Wrapper = styled.div`
  .snackbar {
    -webkit-font-smoothing: antialiased;

    button {
      width: 32px;
      height: 32px;
    }
  }
`;

export const DebriefAssignmentsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const DebriefAssignmentsContent = styled.div`
  padding-top: 46px;

  @media (max-width: 1240px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
