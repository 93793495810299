import types from "./types";
import _ from "lodash";
import { userIdOverride } from "./../../modules/userOverride";

const defaultValues = {
  sessionChecked: false,
  isLoggedIn: false,
  justSignedOut: false,
  lastLoginFailed: false,
  authenticationServerUrl: null,
};

export default function reducer(state = defaultValues, action) {
  if (userIdOverride && action.payload && action.payload.user && _.includes(action.payload.user.roles, "SRH_ADMIN")) {
    action.payload.user.id = userIdOverride;
  }
  switch (action.type) {
    case types.USER_SIGN_IN_START: {
      return {
        ...state,
        lastLoginFailed: false,
      };
    }

    case types.USER_SIGN_IN: {
      return {
        ...state,
        ...action.payload.user,
        isLoggedIn: true,
        justSignedOut: false,
        lastLoginFailed: false,
      };
    }

    case types.USER_SIGN_IN_ERROR: {
      return {
        ...state,
        isLoggedIn: false,
        lastLoginFailed: true,
      };
    }

    case types.USER_SIGN_OUT: {
      return {
        ...state,
        isLoggedIn: false,
        justSignedOut: true,
      };
    }

    case types.USER_VALIDATE_SESSION: {
      return {
        ...state,
        ...action.payload.user,
        sessionChecked: true,
        isLoggedIn: action.payload.sessionValid,
      };
    }

    case types.USER_GET_AUTHENTICATION_SERVER_URL: {
      return {
        ...state,
        authenticationServerUrl: action.payload.authenticationServerUrl,
      };
    }

    default: {
      return state;
    }
  }
}
