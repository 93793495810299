import _ from "lodash";
import React, { Component } from "react";
import { debriefOperations } from "./../../../../redux/debrief/";

import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Wrapper } from "./style";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Clear";

const parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

class DebriefFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
    };
    this.completeStage = this.completeStage.bind(this);
    this.isStageErrorFree = this.isStageErrorFree.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.save = this.save.bind(this);
  }

  completeStage() {
    const { stageIndex, setFormDirty } = this.props;
    if (this.isStageErrorFree()) {
      setFormDirty(false);
      this.props.dispatch(debriefOperations.updateStage(stageIndex));
    } else {
      if (stageIndex === 1) {
        const {
          meetingDateTime,
          location,
          shift,
          numberAttended,
          roleTypes,
          notes,
          keyTakeaways,
        } = this.props.debrief.meeting;
        const notesPass = _.filter(notes.blocks, block => block.text).length > 0;
        const keyTakeawaysPass = _.filter(keyTakeaways.blocks, block => block.text).length > 0;
        const panelBody = document.getElementsByClassName("panel-body")[0];
        if (
          !meetingDateTime ||
          !location ||
          !shift ||
          !numberAttended ||
          !roleTypes ||
          !notesPass ||
          !keyTakeawaysPass
        ) {
          panelBody.scrollTop = 0;
        } else {
          panelBody.scrollTop = panelBody.scrollHeight;
        }
      }
      setFormDirty(true);
    }
  }

  isStageErrorFree() {
    const { debrief, stageIndex } = this.props;
    const {
      meetingDateTime,
      location,
      shift,
      numberAttended,
      roleTypes,
      notes,
      keyTakeaways,
      tellingQuotes,
    } = debrief.meeting;
    switch (stageIndex) {
      case 0:
        return meetingDateTime && location && shift;
      case 1:
        const notesPass = _.filter(notes.blocks, block => block.text).length > 0;
        const keyTakeawaysPass = _.filter(keyTakeaways.blocks, block => block.text).length > 0;
        const tellingQuotesPass = _.filter(tellingQuotes.blocks, block => block.text).length > 0;
        return (
          meetingDateTime &&
          location &&
          shift &&
          numberAttended &&
          roleTypes &&
          notesPass &&
          keyTakeawaysPass &&
          tellingQuotesPass
        );
      case 2:
        const { you, team, needAssistance } = debrief.custom_review_items;
        return you.length + team.length + needAssistance.length > 0;
      case 3:
        const { aim, driveChange, measure, target, whoWhatWhen } = debrief.action;
        return aim && driveChange && measure && target && whoWhatWhen;
      default:
        return true;
    }
  }

  handleSnackbarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  }

  save() {
    this.props.save();
    this.setState({ snackbarOpen: true });
  }

  render() {
    const cookies = parseCookie(document.cookie);
    const { debrief, location, stageIndex } = this.props;
    const stageIndexToStage = ["prepare", "record", "review", "plan", "action"];
    const currentPage = `/l/${location.id}/d/${debrief.id}/${stageIndexToStage[stageIndex]}`;

    const home = cookies.returnUrl || "/";

    let back;
    if (stageIndex > 0) {
      back = `/l/${location.id}/d/${debrief.id}/${stageIndexToStage[stageIndex - 1]}`;
    } else {
      back = home;
    }

    let forward;
    const lastStage = stageIndex >= stageIndexToStage.length - 1;
    if (lastStage) {
      forward = home;
    } else {
      forward = `/l/${location.id}/d/${debrief.id}/${stageIndexToStage[stageIndex + 1]}`;
    }

    const currentStageCompleted = debrief.completed_stages[stageIndex];

    return (
      <Wrapper className="panel-footer">

        {stageIndex === 0 && cookies.returnUrl
          ? <a href={back}><Button>Exit</Button></a>
          : <Link to={back} tabIndex="-1">
              <Button onClick={() => this.props.setFormDirty(false)}>{stageIndex === 0 ? "Exit" : "Back"}</Button>
            </Link>}

        {lastStage && cookies.returnUrl
          ? <a href={forward}><Button variant="contained" color="secondary">Exit</Button></a>
          : <Link to={forward} tabIndex="-1" style={{ marginLeft: "8px" }}>
              <Button variant="contained" color="secondary" onClick={() => this.props.setFormDirty(false)}>{lastStage ? "Exit" : "Next"}</Button>
            </Link>}

        {!currentStageCompleted &&
          !lastStage && (
            <Link to={this.isStageErrorFree() ? forward : currentPage} tabIndex="-1" style={{ marginLeft: "8px" }}>
              <Button variant="contained" color="secondary" onClick={this.completeStage}>
                Complete Step
              </Button>
            </Link>
          )}
        <div style={{ width: "100%" }} />
        {this.props.print && <Button variant="contained" color="default" onClick={this.props.print} style={{marginRight:'1rem'}}>
          Print
        </Button>}
        <Button variant="contained" color="secondary" onClick={this.save}>
          Save
        </Button>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Saved</span>}
          className="snackbar green"
          style={{ display: this.state.snackbarOpen ? "flex" : "none" }}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Wrapper>
    );
  }
}

export default DebriefFooter;
