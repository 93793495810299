export const USER_SIGN_IN_START = "app/user/USER_SIGN_IN_START";
export const USER_SIGN_IN = "app/user/USER_SIGN_IN";
export const USER_SIGN_IN_ERROR = "app/user/USER_SIGN_IN_ERROR";
export const USER_SIGN_OUT = "app/user/USER_SIGN_OUT";
export const USER_VALIDATE_SESSION = "app/user/USER_VALIDATE_SESSION";
export const USER_GET_AUTHENTICATION_SERVER_URL = "app/user/GET_AUTHENTICATION_SERVER_URL";

export default {
  USER_SIGN_IN_START,
  USER_SIGN_IN,
  USER_SIGN_IN_ERROR,
  USER_SIGN_OUT,
  USER_VALIDATE_SESSION,
  USER_GET_AUTHENTICATION_SERVER_URL,
};
