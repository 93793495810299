import _ from "underscore";

import types from "./types";

const defaultValues = {
  currentDebriefId: null,
  currentDebrief: null,
  debriefs: [],
  isLoading: false,
  isLoaded: false,
};

const getCurrentDebrief = (debriefs, currentDebriefId) => _.find(debriefs, d => d.id === currentDebriefId);

export default function reducer(state = defaultValues, action) {
  switch (action.type) {
    case types.LOAD_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.SET_CURRENT_ID: {
      const currentDebrief = getCurrentDebrief(state.debriefs, action.payload.currentDebriefId);

      return {
        ...state,
        ...action.payload,
        currentDebrief: currentDebrief,
      };
    }
    case types.UPDATE: {
      const updatedDebrief = action.payload.debrief;
      // Copies array to new spot in memory
      let updatedDebriefs = state.debriefs.slice();
      // Removes old version of updated survey
      updatedDebriefs = _.reject(updatedDebriefs, debrief => debrief.id === updatedDebrief.id);
      // Add updated version survey back in
      updatedDebriefs = updatedDebriefs.concat(updatedDebrief);

      let newState = {
        ...state,
        debriefs: updatedDebriefs,
      };

      if (updatedDebrief.id === state.currentDebriefId) {
        newState.currentDebrief = updatedDebrief;
      }

      return newState;
    }
    case types.ADD: {
      const newDebriefs = state.debriefs.concat(action.payload.debrief);

      return {
        ...state,
        debriefs: newDebriefs,
      };
    }
    case types.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.CLEAR: {
      return {
        ...defaultValues,
      };
    }
    default: {
      return state;
    }
  }
}
