import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;

  .menu-button {
    position: absolute;
    top: -20px;
    right: -20px;
  }

  h1 {
    margin-bottom: 10px;
  }

  .subtitle {
    color: ${props => props.theme.greyDark};
    font-weight: 300;
    letter-spacing: 0.1px;
  }

  .bucket-header {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;

    img {
      align-self: flex-start;
      margin-top: 4px;
      margin-right: 10px;
    }

    h3 {
      margin-bottom: 2px;
    }

    h4 {
      font-size: 15px;
      font-weight: 300;
      color: ${props => props.theme.greyLight};
      letter-spacing: 0.4px;
      margin: 0;
    }
  }

  .input {
    margin-bottom: 21px;
  }

  .item {
    display: flex;

    button {
      width: 36px;
      height: 36px;
      margin-top: 14px;
      margin-right: 9px;
    }
  }
`;
