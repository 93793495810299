import styled from "styled-components";

export const PanelStyle = styled.div`
  background: #ffffff;
  box-shadow: 0 0 12px 0 ${props => props.theme.light};
  margin-bottom: 46px;

  .panel-header,
  .panel-body,
  .panel-footer {
    padding: 36px;
  }

  .panel-header,
  .panel-footer {
    display: flex;
  }

  .panel-header {
    border-bottom: 4px solid ${props => props.theme.xxxLight};
  }

  .panel-footer {
    border-top: 4px solid ${props => props.theme.xxxLight};
  }

  h1 {
    font-size: 24px;
    font-weight: 300;
    color: ${props => props.theme.primary};
    letter-spacing: 0.5px;
    margin-bottom: 21px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }
`;
