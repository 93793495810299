import styled from "styled-components";

export const DebriefAssignmentListWrapper = styled.div`
  .panel {
    padding: 36px;
    position: relative;
  }

  .menu-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const DebriefAssignmentNone = styled.div`
  padding: 1rem;
  background: ${props => props.theme.xxxLight};
  color: ${props => props.theme.grey};
`;
