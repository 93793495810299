import styled from "styled-components";
import ReactModal from "react-modal";

const StyledReactModal = styled(ReactModal)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: scroll;

  @media (min-width: ${props => props.theme.bpDesktop}) {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      border-radius: 0.125rem;
      box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.3);
      max-width: ${props => props.theme.bpMobile};
    }
  }
`;

export { StyledReactModal };
