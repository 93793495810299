import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { SignInPage, Logo } from "./style.js";
import { userOperations } from "../../redux/user";
import logo from "../../assets/imgs/logo/srh-logo.svg";
import { Dimmer, Loader } from "semantic-ui-react";
import _ from "lodash";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    let checkedIsNewAccount = false;

    const params = new URLSearchParams(window.location.search);
    if (this.props.isLoggedIn) {
      const referrer = params.referrer;
      if (referrer) {
        this.props.history.push(referrer);
      } else {
        this.props.history.push("/");
      }
    }
    const email = params.email;
    if (email) {
      delete params.email;
      this.props.history.push({
        search: `?${_.map(_.toPairs(params), function(param) {
          return `${param[0]}=${param[1]}`;
        }).join("&")}`,
      });
      axios
        .get(`/api/users/is-new-account?email=${email.replace(/\+/g, "%2B")}`, { headers: { Pragma: "no-cache", "Cache-Control":"no-cache" } })
        .then(res => {
          if (res.data.isNewAccount) {
            const host = `${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ""
            }`;
            document.location.href = `${res.data.accountSetupUrl}&referrer=${host}${params.referrer}`;
          } else {
            this.setState({ checkedIsNewAccount: true });
          }
        });
    } else {
      checkedIsNewAccount = true;
    }
    this.state = {
      username: email,
      password: "",
      email,
      checkedIsNewAccount,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.dispatch(userOperations.signIn(username, password));
  }

  render() {
    return (
      <div>
        {!this.state.checkedIsNewAccount && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {this.state.checkedIsNewAccount && (
          <SignInPage>
            <Logo>
              <img src={logo} alt="Safe and Reliable Care" />
            </Logo>
            <form>
              <input name="username" placeholder="Email" onChange={this.handleChange} defaultValue={this.state.email} />
              <input name="password" placeholder="Password" onChange={this.handleChange} type="password" />
              <button onClick={this.handleSubmit}>Sign in</button>
            </form>
            <a
              className="forgot-password"
              href={`${this.props.user.authenticationServerUrl}/send-password-reset?email=${
                this.state.username ? this.state.username.replace(/\+/g, "%2B") : ""
              }&referrer=${document.URL}`}
            >
              Forgot your password?
            </a>
            {this.props.lastLoginFailed && (
              <div style={{ color: "#d81111", marginTop: "20px" }}>Unable to authenticate</div>
            )}
          </SignInPage>
        )}
      </div>
    );
  }
}

export default connect(store => {
  return {
    isLoggedIn: store.user.isLoggedIn,
    lastLoginFailed: store.user.lastLoginFailed,
    user: store.user,
  };
})(SignIn);
