import React, { Component } from "react";
import FormMeeting from "./../../../../components/FormMeeting";

import { Wrapper } from "./style.js";

class DebriefRecord extends Component {
  render() {
    return (
      <Wrapper>
        <h1>Record &amp; Summarize Meeting Notes</h1>
        <FormMeeting
          dispatch={this.props.dispatch}
          formType="record"
          currentForm={this.props.debrief.meeting}
          save={this.props.save}
          formDirty={this.props.formDirty}
        />
      </Wrapper>
    );
  }
}

export default DebriefRecord;
