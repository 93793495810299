import _ from "underscore";
import React, { Component } from "react";
import Panel from "./../../../../components/Panel";
import DebriefAssignmentListItem from "./../DebriefAssignmentListItem";
import { DebriefAssignmentListWrapper, DebriefAssignmentNone } from "./style.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { printable, printpdf } from '../../../../helpers';
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Clear";

class DebriefAssignmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      snackbarOpen: false
    };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.debriefsForLocation = this.debriefsForLocation.bind(this);
    this.locationForDebriefAssignment = this.locationForDebriefAssignment.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.printall = this.printall.bind(this);
  }

  openMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  closeMenu() {
    this.setState({ anchorEl: null });
  }

  locationForDebriefAssignment(da) {
    const { locations } = this.props;
    return _.find(locations, loc => loc.id === da.location_id);
  }

  debriefsForLocation(da) {
    const { debriefs } = this.props;
    const location = this.locationForDebriefAssignment(da);
    if (location) {
      return _.sortBy(_.filter(debriefs, d => d.location_id === location.id && d.status === "active"), s => s.meeting.shift.toLowerCase());
    } else {
      return {};
    }
  }

  printall() {
    const allAssignments = this.props.allAssignments;
    const myAssignments = this.props.sortedDebriefAssignments;

    //transform the entire dataset into a flat array of currentLocation and currentDebrief objects to be consumed by the print view
    const data = myAssignments.map(da => this.debriefsForLocation(da).map(d => ({
      debriefAssignments: allAssignments,
      currentLocation: this.locationForDebriefAssignment(da),
      currentDebrief: d
    }))).reduce( (a,b) => a ? [...a, ...b] : b, []);

    printpdf('Debrief Results for All Work Settings.pdf', printable(data));
    this.setState({ snackbarOpen: true });
    this.closeMenu();
  }

  handleSnackbarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  }


  render() {
    const { sortedDebriefAssignments, dispatch, displayDelete, toggleDisplayDelete, deleteDebrief } = this.props;
    const { anchorEl } = this.state;

    const actualSortedDebriefAssignments = _.sortBy(sortedDebriefAssignments.map( a => ({
      id: a.id,
      assignment: a,
      location: this.locationForDebriefAssignment(a),
      debriefs: this.debriefsForLocation(a)
    })), a => a.location.unit);

    return (
      <DebriefAssignmentListWrapper>
        <Panel>
          <h1>{this.props.title}</h1>
          <IconButton
            aria-label="More"
            aria-owns={anchorEl ? "menu" : null}
            aria-haspopup="true"
            onClick={this.openMenu}
            className="menu-button"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={this.closeMenu}
            style={{ visibility: "visible" }}
          >
            <MenuItem
              onClick={() => {
                this.closeMenu();
                toggleDisplayDelete();
              }}
            >
              {displayDelete ? "Disable Delete" : "Enable Delete"}
            </MenuItem>
            <MenuItem onClick={this.printall}>Print All</MenuItem>

          </Menu>
          {actualSortedDebriefAssignments.map(da => (
            <DebriefAssignmentListItem
              dispatch={dispatch}
              key={da.id}
              location={da.location}
              debriefAssignment={da.assignment}
              debriefs={da.debriefs}
              displayDelete={displayDelete}
              deleteDebrief={deleteDebrief}
            />
          ))}
          {sortedDebriefAssignments.length === 0 && (
            <DebriefAssignmentNone>No Debrief Assignments</DebriefAssignmentNone>
          )}
        </Panel>
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={this.state.snackbarOpen}
            autoHideDuration={2000}
            onClose={this.handleSnackbarClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">Downloading printable PDF</span>}
            className="snackbar"
          />
      </DebriefAssignmentListWrapper>
    );
  }
}

export default DebriefAssignmentList;
