import React, { Component } from "react";
import Logo from "./../Logo";
import { Link } from "react-router-dom";
import { HeaderWrapper, HeaderContent, Title, HeaderSection, HeaderCenter } from "./style.js";
import { userOperations } from "./../../redux/user";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { muiThemeWhite } from "./../../modules/theme";

class Header extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    this.props.dispatch(userOperations.signOut());
  }

  render() {
    return (
      <HeaderWrapper>
        <HeaderContent>
          <HeaderSection>
            <div className="logo">
              <div>
                <Logo />
              </div>
              <Title>Debriefer</Title>
            </div>
          </HeaderSection>
          <HeaderCenter />
          <HeaderSection>
            <MuiThemeProvider theme={muiThemeWhite}>
              <Button onClick={this.signOut} color="primary" style={{ whiteSpace: "nowrap" }}>
                Sign out
              </Button>
            </MuiThemeProvider>
          </HeaderSection>
        </HeaderContent>
      </HeaderWrapper>
    );
  }
}

export default Header;
