import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 14px;

  &.form .input {
    margin-bottom: 14px;
  }
`;

export { Form };
