import _ from "underscore";
import moment from "moment";

const capitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const getName = (da) => {
  if (!da) return;
    let result = '';
  let user = da.user;
  if (user && user.firstName) result = user.firstName;
  if (user && user.lastName) result = `${result} ${user.lastName}`;
  return result;
}

const findassignments = (debriefAssignments, locationId) => {
    const locationDebriefAssignments = _.filter(
        _.flatten(_.values(debriefAssignments)),
        da => da.locationId === locationId
      );

  locationDebriefAssignments.sort((a, b) => {
    if (a.primary && !b.primary) return -1;
    if (!a.primary && b.primary) return 1;
    return 0;
      })

      const managerDA = _.find(locationDebriefAssignments, da => da.role === "manager");
      const managerName = getName(managerDA) || '';
      const facilitatorDA = _.find(locationDebriefAssignments, da => da.role === "facilitator");
      const facilitatorName = getName(facilitatorDA) || '';

  return {
    managerName,
        facilitatorName
      }
}

const getblock = blocks => {
  return blocks
        ? blocks.map(b => b.text).map(i => `<p>${i}</p>`).reduce( (a,b) => a ? `${a}${b}` : b, '')
        : '';
}

const getitems = items => {
  return items
        ? `<ul>${items.filter(i => i.trim()).map(i => `<li>${i}</li>`).reduce( (a,b) => a ? `${a}${b}` : b, '')}</ul>`
        : '';
}    

const buildtitle = (facility, unit, shift) => {
    return facility
        ? `${facility} - ${unit}${shift ? ` - ${shift}` : ''}`
        : `${unit}${shift ? ` - ${shift}` : ''}`
}

const printbody = (location, debrief, managerName, facilitatorName) => {
  return `<div class="wrapper">
        <img src="http://reports-stage.us.safeandreliablecare.com/static/media/logo-stacked.c821fd56.svg" class="logo">

        <td>
            <h1>Debriefer</h1>
        </td>
        
        
        <table class="panel">
            <tr>
                <td colspan="2">
                    <h2>${buildtitle(location.facility, location.unit, debrief.meeting.shift)}</h2>
                </td>
            </tr>
            <tr>
                <td>
                    <h3>Manager</h3>
                    ${managerName}
                </td>
                <td style="text-align:right;">
                    <h3>Facilitator</h3>
                    ${facilitatorName}
                </td>
            </tr>
        </table>
        <br/>
        
        <table class="panel meeting">
            <tr>
                <td colspan="5">
                    <h2>Debriefer Meeting</h2>
                </td>
            </tr>
            <tr>
                <td valign="top">
                    <h3>Date</h3>
                    <span style="white-space: nowrap;">${debrief.meeting.meetingDateTime ? moment(debrief.meeting.meetingDateTime).format('MMMM Do YYYY, h:mm a') : ''}</span>
                </td>
                <td valign="top">
                    <h3>Location</h3>
                    ${debrief.meeting.location || ''}
                </td>
                <td valign="top">
                    <h3>Shift</h3>
                    ${debrief.meeting.shift}
                </td>
                <td valign="top">
                    <h3>Attended</h3>
                    ${debrief.meeting.numberAttended || ''}
                </td>
                <td>
                    <h3>Role Types</h3>
                    ${debrief.meeting.roleTypes || ''}
                </td>
            </tr>
            <tr>
                <td colspan="2" rowspan="2" valign="top" class="dark">
                    <h3>Meeting Notes</h3>
                    <p>
                        ${getblock(debrief.meeting.notes.blocks)}
                    </p>
                </td>
                <td colspan="3" valign="top" class="dark">
                    <h3>Key Takeaways</h3>
                    <p>
                        ${getblock(debrief.meeting.keyTakeaways.blocks)}
                    </p>
                </td>
            </tr>
            <tr>
                <td colspan="3" valign="top" class="dark">
                    <h3>Telling Quotes</h3>
                    <p>
                        ${getblock(debrief.meeting.tellingQuotes.blocks)}
                    </p>
                </td>
            </tr>
        </table>
        <br/>
        <table class="panel">
            <tr>
                <td colspan="5">
                    <h2>Review & Reflect</h2>
                </td>
            </tr>
            <tr class="thirds">
                <td valign="top" class="dark">
                    <h3>For Me, the Manager</h3>
                    ${(debrief.custom_review_items && getitems(debrief.custom_review_items.you)) || ""}
                </td>
                <td valign="top" class="dark">
                    <h3>For the Team</h3>
                    ${(debrief.custom_review_items && getitems(debrief.custom_review_items.team)) || ""}
                </td>
                <td valign="top" class="dark">
                    <h3>Help from Others</h3>
                    ${(debrief.custom_review_items && getitems(debrief.custom_review_items.needAssistance)) || ""}
                </td>
            </tr>
        </table>
        <br/>
        <table class="aims panel">
            <tr>
                <td colspan="5">
                    <h2>Action Plan</h2>
                </td>
            </tr>
            <tr>
            <td valign="top" class="dark">
                <h3>Aim</h3>
                <ul>
                    <li>
                            <label>Opportunity for improvement:</label>
                            ${debrief.action.aim}
                    </li>
                    <li>
                            <label>Target goal for your aim:</label>
                            ${debrief.action.target}
                    </li>
                </ul>
            </td>
            <td valign="top" class="dark">
                <h3>Action Plan</h3>
                <ul>
                    <li>
                        <label>Reached your target goal when:</label>
                        ${debrief.action.measure}
                    </li>
                    <li>
                        <label>Something you will do to drive change:</label>
                        ${debrief.action.driveChange}
                    </li>
                    <li>
                        <label>Who, what, when:</label>
                        ${debrief.action.whoWhatWhen}
                    </li>
                </ul>
                </td>
            </tr>
        </table>

        <div class="footer">
            © ${moment().format('YYYY')} - Confidential Work Product - Do not distribute or reproduce without permission - safeandreliablecare.com
        </div>

    </div>`
}

const printbodydata = (data) => {

  const printdata = Array.isArray(data) ? data : [data];

    return printdata.map( d => {
      const location = d.currentLocation;
      const debrief = d.currentDebrief;
      const { managerName, facilitatorName } = findassignments(d.debriefAssignments, location.id);

      return printbody(location, debrief, managerName, facilitatorName);
    }).reduce( (a,b) => a ? `${a}${b}` : b, '');

}
const printable = (data) => {
    
  const location = data.currentLocation;
  const debrief = data.currentDebrief;

  const title = Array.isArray(data)
        ? buildtitle(data[0].currentLocation.facility,'All Work Settings','All Shifts')
        : buildtitle(location.facility,location.unit,debrief.meeting.shift)

  return `<html>
        <head>
            <title>Safe and Reliable Care Debriefer - ${title}</title>
            <link href="https://fonts.googleapis.com/css?family=Roboto|Roboto+Slab&display=swap" rel="stylesheet">
            <style>
                body {
                    /*background-color:#F7F7F7;*/
                    color:#333333;
                    font-family:Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif;
                    padding:0;
                    margin:0;
                }
                .footer {
                    text-align:center;
                    font-size:smaller;
                    padding:0.5rem 0;
                }
                table {
                    border-spacing:3px;
                }
                h1,h2,h3 {
                    font-family:'Roboto Slab','Helvetica Neue',Arial,Helvetica,sans-serif;
                }

                h2, h3 {
                    margin: 0.5rem 0;
                }
                .wrapper {
                    max-width: 10in;
                    margin:0 auto;
                    ${Array.isArray(data) ? 'page-break-after: always;' : ''}
                }
                td {
                    padding:5px;
                }
                td.dark {
                    background-color:rgba(237, 239, 245,0.5);
                }
                .panel {
                    padding:1rem;
                    background:#ffffff;
                    width:100%;
                    border:1px solid #999999;
                }
                .panel.meeting td:nth-of-type(2) {
                    width:40%;
                }
                .aims li > label {
                    display:block;
                    text-decoration: underline;
                }
                .thirds ul {
                    padding:0;
                    margin:0;
                }
                .thirds li {
                    list-style-type:none;
                    margin-bottom:1rem;
                }
                .aims ul {
                    margin:0;
                    padding:0;
                }
                .aims li {
                    list-style-type: none;
                    margin-bottom:1rem;
                    
                }
                .logo {
                    margin:1rem auto;
                    display:block;
                }
                h1, h2,h3 {
                    color:#006E94;
                }
                h1 {
                    width:100%;
                    text-align:center;
                    margin-top:0.5rem;
                }
                .thirds td {
                    width:33%;
                }

                #closemessage {
                    background-color: #FA3E3E;
                    color: #fff;
                    font-size: 16px;
                    text-align: center;
                    padding: 1.25rem 0;
                    position: relative;
                    opacity:0;
                    transform: opacity 1000ms ease;
                }
                #closemessage.opened {
                    opacity:1;
                }
                #closemessage a {
                    color:#fff;
                    font-weight:bold;
                }
                #closemessage button {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    background: transparent;
                    color: #fff;
                    border: none;
                    font-weight: bold;
                    font-size: 19px;
                    cursor: pointer;
                }
                @media (print) {
                    #closemessage {
                        display:none;
                    }    
                }

            </style>
        </head>
        <body>
            <div id="closemessage">
                After printing this document you may <a href="javascript:window.close()">close</a> this tab.
            </div>
            ${printbodydata(data)}
        </body>
        </html>`
};

const printpdf = (filename, html) => {
  const oldIframe = document.getElementById("downloadIFrame");
  if (oldIframe) {
    oldIframe.remove();
  }

  const iframe = document.createElement("iframe");
  iframe.name = "downloadFrame";
  iframe.id = "downloadIFrame";
  iframe.style.display = "none";
  iframe.src = "";
  document.body.appendChild(iframe);

  const oldForm = document.getElementById("form");
  if (oldForm) {
    oldForm.remove();
  }

  const form = document.createElement("form");
  form.id = "form";
  form.method = "post";
  form.action = `https://reports.safeandreliablecare.com/api/pdf?orientation=portrait`;
  form.target = "downloadFrame";
  document.body.appendChild(form);

  const filenameInput = document.createElement("input");
  filenameInput.type = "hidden";
  filenameInput.name = "filename";
  filenameInput.value = filename;
  form.appendChild(filenameInput);

  const htmlInput = document.createElement("input");
  htmlInput.type = "hidden";
  htmlInput.name = "html";
  htmlInput.value = html;
  form.appendChild(htmlInput);

  form.submit();
}

export { capitalize, printable, findassignments, printpdf };
