import styled from "styled-components";

export const Style = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  position: absolute;
  flex-direction: column;
  background: ${props => props.theme.xxxLight};

  .scrollable {
    overflow: auto;
  }
`;
