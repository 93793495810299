const LOAD_ALL = "app/debriefAssignment/LOAD_ALL";
const SET_ALL = "app/debriefAssignment/SET_ALL";
const SET_CURRENT_ID = "app/debriefAssignment/SET_CURRENT_ID";
const CLEAR = "app/debriefAssignment/CLEAR";

export default {
  LOAD_ALL,
  SET_ALL,
  SET_CURRENT_ID,
  CLEAR,
};
