import styled from "styled-components";

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.grey};
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 100;
  line-height: 0;

  &.active {
    background: ${props => props.theme.secondary};
  }

  &.complete {
    background: ${props => props.theme.primaryLight};
  }
`;

export { Circle };
