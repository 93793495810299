import types from "./types";

const loadAll = () => {
  return {
    type: types.LOAD_ALL,
    payload: {
      isLoading: true,
    },
  };
};

const create = debrief => {
  return {
    type: types.CREATE,
    payload: {
      debrief,
    },
  };
};

const update = debrief => {
  return {
    type: types.UPDATE,
    payload: {
      debrief,
    },
  };
};

const add = debrief => {
  return {
    type: types.ADD,
    payload: {
      debrief,
    },
  };
};

const setCurrentId = currentDebriefId => {
  return {
    type: types.SET_CURRENT_ID,
    payload: {
      currentDebriefId,
    },
  };
};

const setAll = debriefs => {
  return {
    type: types.SET_ALL,
    payload: {
      debriefs,
      isLoading: false,
      isLoaded: true,
    },
  };
};

const clear = () => {
  return {
    type: types.CLEAR,
  };
};

export default {
  loadAll,
  create,
  update,
  add,
  setCurrentId,
  setAll,
  clear,
};
