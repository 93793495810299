import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import TypographyTitle from "../../../components/TypographyTitle";
import TypographyParagraph from "../../../components/TypographyParagraph";

const useStyles = makeStyles((theme) => ({
  row: {
    paddingTop: 208,
    display: "flex",
    justifyContent: "center",
  },
  column: {
    paddingLeft: theme.spacing(3),
  },
  oops: {
    margin: `${theme.spacing(3.5, 0, 2, 0)} !important`,
    fontSize: 18,
    fontWeight: "bold",
    color: "#006e94",
  },
  heading: {
    margin: theme.spacing(2, 0, 3, 0),
    width: 330,
    color: grey[800],
  },
  text: {
    fontSize: 16,
  },
}));

function SummaryReportEmptyState() {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.column}>
        <img src="/blocked-sign.svg" alt="blocked" />
      </div>
      <div className={classes.column}>
        <TypographyParagraph className={classes.oops}>Oops</TypographyParagraph>
        <TypographyTitle className={classes.heading}>Looks like you don't have access to any Work Settings to debrief.</TypographyTitle>
        <TypographyParagraph className={classes.text}>Please contact your Survey Lead for more information.</TypographyParagraph>
      </div>
    </div>
  );
}

export default SummaryReportEmptyState;
