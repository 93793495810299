import { combineReducers } from 'redux';

import modal              from './../redux/modal';
import theme              from './../redux/theme';
import location           from './../redux/location';
import debrief            from './../redux/debrief';
import debriefAssignment  from './../redux/debriefAssignment';
import user               from './../redux/user';

export default combineReducers({
  modal,
  theme,
  location,
  debrief,
  debriefAssignment,
  user,
});
