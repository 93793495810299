import types from "./types";
const defaultValues = {
  themes: {},
  isLoading: false,
  isLoaded: false,
};

export default function reducer(state = defaultValues, action) {
  switch (action.type) {
    case types.LOAD_ALL: {
      return {
        ...defaultValues,
        ...action.payload,
      };
    }
    case types.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
