import styled from "styled-components";

export const Wrapper = styled.div`
  .empty-list {
    height: 200px;
    background: #f3f3f3;
    padding-top: 70px;
    margin-bottom: 28px;

    p {
      text-align: center;
      color: ${props => props.theme.greyXDark};
    }

    > div {
      justify-content: center;
      margin-top: 14px;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  padding: 1rem 0;
  background-color: ${props => props.theme.white};
`;

export const ListItemPrimary = styled.div`
  color: ${props => props.theme.black};
  margin-right: 1rem;
  font-size: 18px;
`;

export const DebriefsTableWrapper = styled.div``;

export const DebriefsTable = styled.table`
  width: 100%;
`;

export const DebriefsTableHead = styled.thead`
  font-size: 14px;
  background: ${props => props.theme.light};
  color: ${props => props.theme.greyDark};
  font-weight: 400;
`;
export const DebriefsTableRow = styled.tr``;

export const DebriefsTableHeader = styled.th`
  padding: 24px 0;
  text-align: center;
  font-weight: inherit;
  width: 15%;

  &.left {
    text-align: left;
    padding-left: 28px;
    width: 20%;
  }

  &:first-child {
    padding-left: 28px;
  }

  &:last-child {
    padding-right: 28px;
  }

  &.archive {
    width: 7%;
  }
`;
