import _ from "underscore";
import types from "./types";

const defaultValues = {
  locations: {},
  currentLocation: {},
  currentLocationId: null,
  isLoading: false,
  isLoaded: false,
};

const getCurrentLocation = (location, currentLocationId) => {
  const processedlocation = _.flatten(_.values(location));
  return _.find(processedlocation, da => da.id === currentLocationId);
};

export default function reducer(state = defaultValues, action) {
  switch (action.type) {
    case types.LOAD_ALL: {
      return {
        ...defaultValues,
        ...action.payload,
      };
    }
    case types.SET_ALL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.SET_CURRENT_ID: {
      const currentLocation = getCurrentLocation(state.locations, action.payload.currentLocationId);

      return {
        ...state,
        ...action.payload,
        currentLocation,
      };
    }
    default: {
      return state;
    }
  }
}
