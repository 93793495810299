import React, { Component } from "react";
import { debriefOperations } from "./../../redux/debrief/";
//import Slider from "react-rangeslider";
//import "react-rangeslider/lib/index.css";
import Slider from '@material-ui/core/Slider';
import DatePicker from "./../DatePicker";
import Input from "./../Input";
import { Form } from "./style";
import moment from "moment";

class FormTakeAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.currentForm
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
  }

  handleDrag(event, value) {
    this.setState({progress:value});
  };

  handleChange(event, value) {
    let data = {
      progress: value
    };
    // Make sure component state has fully updates before dispatching redux update
    if (value === 100 && !this.state.completedDate) {
      data.completedDate = moment();
      this.props.dispatch(debriefOperations.updateStage(4));
    } else if (value <= 100) {
      data.completedDate = null;
      this.props.dispatch(debriefOperations.updateStage(4, false));
    }

    this.setState(data, () => {
      this.props.dispatch(debriefOperations.updateTakeAction(this.state, true));
      this.props.save();
    });
  }

  handleDateChange(event) {
    this.setState({completedDate: event.target.value}, () => {
      this.props.dispatch(debriefOperations.updateTakeAction(this.state, true));
      this.props.save();
    });

  }

  handleNotesChange(event) {
    this.setState({notes: event.target.value}, () => {
      this.props.dispatch(debriefOperations.updateTakeAction(this.state, true));
      this.props.save();
    });
  }

  render() {
    const { formDirty } = this.props;
    return (
      <Form>
        <h3 style={{ display: "inline-block" }}>Overall Progress:</h3>
        <div className="value">{this.state.progress}%</div>

        <div className="slider">
          <Slider aria-label="Volume" value={this.state.progress} onChange={this.handleDrag} onChangeCommitted={this.handleChange} />
        </div>

        <DatePicker
          id="completedDate"
          label={"Completion Date"}
          helperText="Choose the date you completed your action plan"
          disabled={this.state.progress < 100}
          onChange={this.handleDateChange}
          onBlur={this.props.save}
          value={this.state.completedDate}
          error={!this.state.completedDate && formDirty}
          fullWidth
          multiline
        />

        <Input
          id="notes"
          label="Notes"
          helperText="Notes about your progress"
          type="text"
          onChange={this.handleNotesChange}
          onBlur={this.props.save}
          value={this.state.notes}
          fullWidth
          multiline
        />
      </Form>
    );
  }
}

export default FormTakeAction;
