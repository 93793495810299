import React, { Component } from "react";
import { Background, Wrapper, Title, Subtitle } from "./style.js";

class SectionHeader extends Component {
  render() {
    const { title, subtitle } = this.props;

    return (
      <Background>
        <Wrapper>
          <Title>{title}</Title>
          <Subtitle className="text-white">{subtitle}</Subtitle>
        </Wrapper>
      </Background>
    );
  }
}

export default SectionHeader;
