import _ from "underscore";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { locationOperations } from "./../../redux/location";
import { debriefOperations } from "./../../redux/debrief";
import { Wrapper, Container, Nav } from "./style.js";
import { printable, printpdf } from '../../helpers';

import PageContentWrapper from "./../../components/PageContentWrapper";
import Header from "./../../components/Header";
import Panel from "./../../components/Panel";
import NumberCircle from "./../../components/NumberCircle";
import DebriefFooter from "./components/DebriefFooter";
import DebriefPrepare from "./components/DebriefPrepare/";
import DebriefRecord from "./components/DebriefRecord/";
import DebriefReview from "./components/DebriefReview/";
import DebriefPlan from "./components/DebriefPlan/";
import DebriefAction from "./components/DebriefAction/";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";

import HomeIcon from "@material-ui/icons/Home";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Clear";

const parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

class Debrief extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formDirty: false,
      snackbarOpen: false,
      snackbarDownload: false
    };
    this.setFormDirty = this.setFormDirty.bind(this);
    this.setSnackbarState = this.setSnackbarState.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.print = this.print.bind(this);
  }

  setFormDirty(formDirty) {
    this.setState({ formDirty });
    this.setSnackbarState(formDirty);
  }

  setSnackbarState(snackbarOpen) {
    this.setState({ snackbarOpen });
  }

  handleSnackbarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false, snackbarDownload: false });
  }

  componentWillMount() {
    const debriefId = this.props.match.params.debriefId;
    const locationId = this.props.match.params.locationId;
    this.headerLink = this.headerLink.bind(this);
    this.headerStep = this.headerStep.bind(this);
    this.save = this.save.bind(this);
    this.props.dispatch(locationOperations.setCurrentId(locationId));
    this.props.dispatch(debriefOperations.setCurrentId(debriefId));
  }

  stageToComponents = {
    prepare: [DebriefPrepare],
    record: [DebriefRecord],
    review: [DebriefReview],
    plan: [DebriefPlan],
    action: [DebriefAction],
  };

  componentDidUpdate(prevProps, prevState) {
    const prevStage = prevProps.match.params.stage;
    const stage = this.props.match.params.stage;
    if (prevStage !== stage) {
      document.getElementById("scrollable").scrollTop = 0;
    }
  }

  stageIndex(name) {
    return _.indexOf(_.keys(this.stageToComponents), name);
  }

  headerLink(stage, stageIndex, location, debrief) {
    const { currentLocation } = this.props;
    const cookies = parseCookie(document.cookie);
    const facility = cookies.datasetName || currentLocation.facility;

    return (
      <div className="nav">
        {cookies.returnUrl ? <a href={cookies.returnUrl}><HomeIcon/></a> : <HomeIcon />}
        <ChevronRightIcon />
        {facility}
        <ChevronRightIcon />
        {currentLocation.unit}
        {debrief.meeting.shift && <ChevronRightIcon />}
        {debrief.meeting.shift && debrief.meeting.shift}
      </div>
    );
  }

  headerStep(currentStageIndex, stageIndex, stage, title, subtitle) {
    const { currentLocation, currentDebrief } = this.props;
    const active = currentStageIndex === stageIndex;
    const complete = currentDebrief.completed_stages[stageIndex];
    return (
      <Link
        to={`/l/${currentLocation.id}/d/${currentDebrief.id}/${stage}`}
        className={`header-step ${active ? "active" : ""} clickable`}
        onClick={() => this.setFormDirty(false)}
      >
        <NumberCircle active={active} number={stageIndex + 1} complete={complete} />
        <div>
          <h3>{title}</h3>
          <h4>{subtitle}</h4>
        </div>
      </Link>
    );
  }

  save() {
    this.props.dispatch(debriefOperations.save());
  }

  print() {
    const { currentDebrief, currentLocation } = this.props;    
    const filename = 
      `Debrief Results for ${currentLocation.facility} ${currentLocation.unit}${currentDebrief.meeting.shift ? ` - ${currentDebrief.meeting.shift}` : ''}.pdf`
      .replace(/[, ]+/g, " ").trim();
    printpdf(filename, printable(this.props));
    this.setState({ snackbarDownload: true });
  }  

  render() {
    const { currentDebrief, currentLocation, debriefAssignments, dispatch } = this.props;
    const { formDirty } = this.state;
    const survey = _.find(this.props.surveys, s => s.name === currentLocation.surveyName);

    const stage = this.props.match.params.stage;
    const stageIndex = this.stageIndex(stage);
    const components = this.stageToComponents[stage];

    const debriefId = this.props.match.params.debriefId;

    if (currentDebrief && currentDebrief.id === debriefId) {  
      return (
        <Wrapper>
          <PageContentWrapper>
            <Header dispatch={dispatch} />
            <div id="scrollable" className="scrollable">
              <Container>
                <Nav margin={stageIndex === -1}>
                  {this.headerLink(stage, stageIndex, currentLocation, currentDebrief)}
                </Nav>
                <Panel>
                  <div className="panel-header">
                    {this.headerStep(stageIndex, 0, "prepare", "Prepare to Debrief", "Facilitator & Manager")}
                    <div className="header-fill-gap" />
                    {this.headerStep(stageIndex, 1, "record", "Record Notes", "Facilitator")}
                    <div className="header-fill-gap" />
                    {this.headerStep(stageIndex, 2, "review", "Review & Reflect", "Manager")}
                    <div className="header-fill-gap" />
                    {this.headerStep(stageIndex, 3, "plan", "Develop Action Plan", "Manager & Staff")}
                    <div className="header-fill-gap" />
                    {this.headerStep(stageIndex, 4, "action", "Take Action", "Manager & Staff")}
                  </div>
                  <div className="panel-body">
                    {components.map((StageComponent, i) => (
                      <StageComponent
                        key={"dbc-" + i}
                        location={currentLocation}
                        debrief={currentDebrief}
                        debriefAssignments={debriefAssignments}
                        survey={survey}
                        tip={this.props.match.params.tip}
                        dispatch={this.props.dispatch}
                        stageIndex={stageIndex}
                        save={this.save}
                        formDirty={formDirty}
                      />
                    ))}
                  </div>
                  <DebriefFooter
                    location={currentLocation}
                    debrief={currentDebrief}
                    dispatch={this.props.dispatch}
                    stageIndex={stageIndex}
                    setFormDirty={this.setFormDirty}
                    save={this.save}
                    print={this.print}
                  />
                </Panel>
              </Container>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.snackbarOpen}
              autoHideDuration={6000}
              onClose={this.handleSnackbarClose}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={
                <span id="message-id">You need to fill out more fields before you can mark this step as complete.</span>
              }
              className="snackbar red"
              style={{ display: this.state.snackbarOpen ? "flex" : "none" }}
              action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarClose}>
                  <CloseIcon />
                </IconButton>,
              ]}
            />
            <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={this.state.snackbarDownload}
            autoHideDuration={2000}
            onClose={this.handleSnackbarClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">Downloading printable PDF</span>}
            className="snackbar"
          />
          </PageContentWrapper>
        </Wrapper>
      );
    }
    return null;
  }
}

export default connect(store => {
  return {
    debriefsLoaded: store.debrief.isLoaded,
    currentDebrief: store.debrief.currentDebrief,
    debriefAssignments: store.debriefAssignment.debriefAssignments,
    currentLocation: store.location.currentLocation
  };
})(Debrief);
