import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { debriefOperations } from "./../../../../redux/debrief";
import bucket from "./../../../../assets/imgs/icons/bucket.png";
import FormCustomItem from "./../../../../components/FormCustomItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "./../../../../components/Input";
import CloseIcon from "@material-ui/icons/Clear";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Snackbar from "@material-ui/core/Snackbar";

import { Wrapper } from "./style.js";

class DebriefReview extends Component {
  constructor(props) {
    super(props);
    const { you, team, needAssistance } = props.debrief.custom_review_items;
    this.state = {
      anchorEl: null,
      showDelete: false,
      snackbarOpen: false,
      undoItems: null,
      undoGroup: null,
      you,
      team,
      needAssistance,
    };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleShowDelete = this.toggleShowDelete.bind(this);
    this.undoDelete = this.undoDelete.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.deleteCustomItem = this.deleteCustomItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getItem = this.getItem.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const change = {};
    const { you, team, needAssistance } = nextProps.debrief.custom_review_items;
    if (you.length !== this.state.you.length) {
      change.you = you;
    }
    if (team.length !== this.state.team.length) {
      change.team = team;
    }
    if (needAssistance.length !== this.state.needAssistance.length) {
      change.needAssistance = needAssistance;
    }
    this.setState(change);
  }

  openMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  closeMenu() {
    this.setState({ anchorEl: null });
  }

  toggleShowDelete() {
    this.setState({
      anchorEl: null,
      showDelete: !this.state.showDelete,
    });
  }

  undoDelete() {
    const { undoItems, undoGroup } = this.state;
    this.setState({ snackbarOpen: false });
    this.props.dispatch(debriefOperations.saveCustomItems(undoItems, undoGroup));
  }

  handleSnackbarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  }

  deleteCustomItem(item, group) {
    const { dispatch, debrief } = this.props;
    this.setState({
      snackbarOpen: true,
      undoItems: debrief.custom_review_items[group],
      undoGroup: group,
    });
    dispatch(debriefOperations.removeCustomItem(item, group));
  }

  handleChange(group, index, event) {
    const list = this.state[group];
    list[index] = event.target.value;

    const change = {};
    change[group] = list;

    // Make sure component state has fully updates before dispatching redux update
    this.setState(change, () => {
      this.props.dispatch(debriefOperations.saveCustomItems(list, group));
    });
  }

  getItem(item, i, group) {
    const { showDelete } = this.state;
    let label;
    let helperText;
    switch (group) {
      case "you":
        label = "Item I will tackle";
        helperText = "Something from the findings that I will tackle";
        break;
      case "team":
        label = "Item for our team";
        helperText = "Something from the findings that our team can tackle";
        break;
      case "needAssistance":
        label = "Item we need help with";
        helperText = "Item that we need help to tackle";
        break;
    }
    return (
      <div className="item" key={i}>
        {showDelete && (
          <IconButton onClick={() => this.deleteCustomItem(item, group)} color="primary">
            <CloseIcon />
          </IconButton>
        )}
        <Input
          id={`${i}`}
          label={label}
          helperText={helperText}
          type="text"
          onChange={event => this.handleChange(group, i, event)}
          onBlur={this.props.save}
          value={`${item}`}
          fullWidth
          multiline
        />
      </div>
    );
  }

  render() {
    const { you, team, needAssistance } = this.state;
    const { anchorEl, showDelete } = this.state;
    return (
      <Wrapper>
        <h1>Review &amp; Reflect</h1>
        <p className="subtitle">
          After reviewing the meeting notes taken by the facilitator, take what you have learned and organize action
          items into three different buckets.
        </p>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? "menu" : null}
          aria-haspopup="true"
          onClick={this.openMenu}
          className="menu-button"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={this.closeMenu}
          style={{ visibility: "visible" }}
        >
          <MenuItem onClick={this.toggleShowDelete}>{showDelete ? "Disable Delete" : "Enable Delete"}</MenuItem>
        </Menu>
        <Grid columns="3">
          <Grid.Row>
            <Grid.Column>
              <div className="bucket-header">
                <img src={bucket} />
                <div>
                  <h3>For Me, the Manager</h3>
                  <h4>Items I can tackle</h4>
                </div>
              </div>
              {you.map((item, i) => this.getItem(item, i, "you"))}
              <FormCustomItem dispatch={this.props.dispatch} group="you" data={you} save={this.props.save} />
            </Grid.Column>
            <Grid.Column>
              <div className="bucket-header">
                <img src={bucket} />
                <div>
                  <h3>For the Team</h3>
                  <h4>Items our team can tackle locally</h4>
                </div>
              </div>
              {team.map((item, i) => this.getItem(item, i, "team"))}
              <FormCustomItem dispatch={this.props.dispatch} group="team" data={team} save={this.props.save} />
            </Grid.Column>
            <Grid.Column>
              <div className="bucket-header">
                <img src={bucket} />
                <div>
                  <h3>Help from Others</h3>
                  <h4>Items we need help with</h4>
                </div>
              </div>
              {needAssistance.map((item, i) => this.getItem(item, i, "needAssistance"))}
              <FormCustomItem dispatch={this.props.dispatch} group="needAssistance" data={needAssistance} save={this.props.save} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Item deleted</span>}
          className="snackbar"
          style={{ display: this.state.snackbarOpen ? "flex" : "none" }}
          action={[
            <Button key="undo" color="secondary" size="small" onClick={this.undoDelete}>
              UNDO
            </Button>,
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Wrapper>
    );
  }
}

export default DebriefReview;
