import _ from "lodash";
import actions from "./actions";

const loadAll = force => (dispatch, getState) => {
  const location = getState().location;
  if (!force && (location.isLoading || location.isLoaded)) {
    return;
  }

  dispatch(actions.loadAll());

  fetch("/api/locations", { credentials: "include", headers: { Pragma: "no-cache", "Cache-Control":"no-cache" } })
    .then(res => {
      if (res.status === 401) {
        throw Error("Unauthorized");
      }
      return res.json();
    })
    .then(data => {
      dispatch(actions.setAll(data));
    })
    .catch(e => {
      console.log("Parsing locations failed", e);
    });
};

const setCurrentId = Id => (dispatch, getState) => {
  const state = getState();
  if (!_.find(state.debrief.debriefs, d => d.id === Id)) {
    fetch('/api/locations/' + Id).then( r => r.json()).then( d => {
      dispatch(actions.setAll([...state.location.locations, ...d]));
      dispatch(actions.setCurrentId(Id));
    })
  } else {
    dispatch(actions.setCurrentId(Id));
  }

};

export default {
  loadAll,
  setCurrentId,
};
