import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  height: 61px;
  min-height: 61px;
  padding: 0 7px;

  .logo {
    margin-right: 2rem;
    display: flex;

    > div {
      align-self: center;
    }
  }
`;

export const Title = styled.div`
  color: #f1f4f5;
  font-size: 12px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const HeaderContent = styled.div`
  max-width: ${props => props.theme.bpDesktopLarge};
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const HeaderNavItem = styled(Link)`
  text-decoration: ${props => (props.selected ? "underline" : "none")};
  color: ${props => props.theme.white};
  margin: auto 1rem;

  &:hover {
    color: ${props => props.theme.xxxLight};
  }
`;

export const HeaderSection = styled.div`
  display: flex;
`;

export const HeaderCenter = styled.div`
  width: 100%;
`;
