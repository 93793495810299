import styled from "styled-components";
import dashboardBg from "./../../assets/imgs/bg/debrief-home-bg.png";
import dashboardBgWide from "./../../assets/imgs/bg/debrief-home-bg-wide.png";

export const Background = styled.div`
  display: flex;
  align-items: center;
  padding: 0 7px;
  background-image: url(${dashboardBg});
  background-size: ${props => props.theme.bpTablet};
  background-position: center;
  height: 150px;

  @media (min-width: ${props => props.theme.bpTablet}) {
    background-size: ${props => props.theme.bpDesktop};
    height: 250px;
  }

  @media (min-width: ${props => props.theme.bpDesktop}) {
    background-size: 100%;
    height: 250px;
  }

  @media (min-width: ${props => props.theme.bpDesktopLarge}) {
    height: 310px;
  }

  @media (min-width: 1450px) {
    background-image: url(${dashboardBgWide});
  }
`;

export const Wrapper = styled.div`
  width: 1200px;
  margin: auto;
`;

export const Title = styled.h6`
  font-size: 16px;
  letter-spacing: 1px;
  color: ${props => props.theme.primaryXLight};
  text-transform: uppercase;
  font-weight: 400;
`;

export const Subtitle = styled.h4`
  font-size: 36px;
  font-weight: 100;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 26px;
  color: ${props => props.theme.white};
`;
