import styled from "styled-components";

export const Wrapper = styled.div`
  h3 {
    margin-top: 40px;

    &:first-child {
      margin-top: 10px;
    }
  }

  .input {
    margin-bottom: 21px;
  }

  ul.bullet {
    li {
      margin-top: 21px;
      list-style: none;
      list-style-position: outside;

      &:before {
        content: "•";
        font-size: 16px;
        color: ${props => props.theme.secondary};
        position: absolute;
        left: 40px;
      }
    }
  }
`;
