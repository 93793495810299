import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 24,
    lineHeight: "30px",
    fontWeight: 300,
    marginBottom: 6,
    fontFamily: "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
  },
  white: {
    color: "white",
  },
}));

function TypographyTitle(props) {
  const { className, customColor, ...other } = props;
  const classes = useStyles();
  return <h1 className={clsx(classes.root, { [classes.white]: customColor === "white" }, className)} {...other} />;
}

TypographyTitle.propTypes = {
  customColor: PropTypes.oneOf(["white"]),
  className: PropTypes.string,
};

export default TypographyTitle;
