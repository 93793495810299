import { createTheme } from "@material-ui/core/styles";

export const theme = {
  primary: "#006E94",
  primaryLight: "#138BB5",
  primaryXLight: "#9FD6DC",
  primaryXXLight: "#B3EFF5",
  primaryDark: "#005C7A",
  secondary: "#F29F5C",
  secondaryLight: "#FFCF8D",
  secondaryDark: "#EE832D",
  green: "#69BF75",
  greenDark: "#3F9F4E",
  yellow: "#E7BE3E",
  redXXXLight: "#FFE7E7",
  red: "#D95E62",
  redDark: "#D32F2F",
  white: "#FFFFFF",
  xxxLight: "#F7F7F7",
  xxLight: "#F3F3F3",
  xLight: "#EEEEEE",
  light: "#E6E6E6",
  greyXXXLight: "#DDDDDD",
  greyXXLight: "#CCCCCC",
  greyXLight: "#BBBBBB",
  greyLight: "#AAAAAA",
  grey: "#999999",
  greyDark: "#888888",
  greyXDark: "#777777",
  greyXXDark: "#666666",
  greyXXXDark: "#555555",
  dark: "#444444",
  xDark: "#333333",
  xxDark: "#222222",
  xxxDark: "#111111",
  black: "#000000",
  bpMobile: "576px",
  bpTablet: "768px",
  bpDesktop: "992px",
  bpDesktopLarge: "1200px",
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      light: theme.primaryLight,
      main: theme.primary,
      dark: "#457AB2",
      contrastText: theme.white,
    },
    secondary: {
      light: theme.secondaryLight,
      main: theme.secondary,
      dark: theme.secondaryDark,
      contrastText: theme.white,
    },
  },
});

export const muiThemeWhite = createTheme({
  palette: {
    primary: {
      light: theme.white,
      main: theme.white,
      dark: theme.xxxLight,
      contrastText: theme.black,
    },
  },
});
