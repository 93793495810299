import React, { Component } from "react";
import { debriefOperations } from "./../../../../redux/debrief";
import Button from "@material-ui/core/Button";
import { Wrapper } from "./style";

class DebriefNewListItem extends Component {
  constructor(props) {
    super(props);
    this.createNewDebrief = this.createNewDebrief.bind(this);
  }

  createNewDebrief() {
    const { dispatch, location } = this.props;

    dispatch(debriefOperations.create(location.id));
  }

  render() {
    return (
      <Wrapper>
        <Button onClick={this.createNewDebrief} color="secondary" variant="contained">
          Create Debrief
        </Button>
      </Wrapper>
    );
  }
}

export default DebriefNewListItem;
