import axios from "axios";
import actions from "./actions";

import { locationOperations } from "./../location";

import { debriefOperations } from "./../debrief";
import { debriefAssignmentOperations } from "./../debriefAssignment";

const loadAllData = dispatch => {
  const force = true;
  dispatch(debriefAssignmentOperations.loadAll(force));
  dispatch(locationOperations.loadAll(force));
  dispatch(debriefOperations.loadAll(force));
};

const signIn = (username, password) => (dispatch, getState) => {
  dispatch(actions.signInStart());
  axios
    .post("/api/sign-in", { username, password })
    .then(res => {
      dispatch(actions.signIn({ ...res.data }));
      loadAllData(dispatch);
    })
    .catch(err => {
      dispatch(actions.signInError(err));
    });
};

const signOut = () => (dispatch, getState) => {
  axios.get("/api/sign-out", { headers: { Pragma: "no-cache", "Cache-Control":"no-cache" } }).then(res => {
    dispatch(actions.signOut(res.data));
  });
};

const validateSession = () => (dispatch, getState) => {
  axios.get("/api/session-status", { headers: { Pragma: "no-cache", "Cache-Control":"no-cache" } }).then(res => {
    if (res.data.sso) {
      window.location = `https://reports-${res.data.sso}.us.safeandreliablecare.com/api/sso/login`;
    } else {
      dispatch(actions.validateSession(res.data));
      if (res.data.sessionValid) {
        loadAllData(dispatch);
      }
    }
  });
};

const getAuthenticationServerUrl = () => (dispatch, getState) => {
  axios.get("/api/authentication-server-url", { headers: { Pragma: "no-cache", "Cache-Control":"no-cache" } }).then(res => {
    dispatch(actions.getAuthenticationServerUrl(res.data));
  });
};

export default {
  signIn,
  signOut,
  validateSession,
  getAuthenticationServerUrl,
};
