import styled from "styled-components";

export const Wrapper = styled.div`
  .snackbar {
    -webkit-font-smoothing: antialiased;

    &.red > div {
      background: ${props => props.theme.redDark};
    }

    &.green > div {
      background: ${props => props.theme.greenDark};
    }

    button {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Container = styled.div`
  max-width: ${props => props.theme.bpDesktopLarge};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.bpMobile}) {
    margin-bottom: 0;
  }

  .panel {
    margin-bottom: 0;
  }

  .nav {
    display: flex;
    align-items: center;

    svg {
      width: 26px;
      height: 26px;
    }

    a {
      display: flex;
    }

    a svg {
      width: 18px;
      height: 18px;
    }
  }

  .header-step {
    display: flex;
    cursor: default;
    pointer-events: none;
    user-select: none;

    &.clickable {
      cursor: pointer;
      pointer-events: all;

      &:hover {
        background: ${props => props.theme.xxxLight};
        box-shadow: 0 0 0 10px ${props => props.theme.xxxLight};
      }
    }

    .number-circle {
      margin-top: 2px;
    }

    h3,
    h4 {
      font-weight: 300;
      color: ${props => props.theme.greyLight};
      letter-spacing: 0.4px;
      margin: 0;
      margin-left: 10px;
      white-space: nowrap;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 1px;
    }

    h4 {
      font-size: 12px;
    }

    &.active {
      h3 {
        color: ${props => props.theme.black};
        font-weight: 500;
      }
    }
  }

  .header-fill-gap {
    background: ${props => props.theme.greyXXXLight};
    box-shadow: 0px -1px 0px 0px ${props => props.theme.xxxLight};
    flex-grow: 1;
    height: 1px;
    margin: 14px 16px;
  }

  .panel-body {
    overflow-y: auto;
    height: 440px;
    @media (min-height: 725px) {
      height: 450px;
    }
    @media (min-height: 735px) {
      height: 460px;
    }
    @media (min-height: 745px) {
      height: 470px;
    }
    @media (min-height: 755px) {
      height: 480px;
    }
    @media (min-height: 765px) {
      height: 490px;
    }
    @media (min-height: 775px) {
      height: 500px;
    }
    @media (min-height: 790px) {
      height: 515px;
    }
    @media (min-height: 815px) {
      height: 540px;
    }
    @media (min-height: 840px) {
      height: 565px;
    }
    @media (min-height: 865px) {
      height: 590px;
    }
    @media (min-height: 890px) {
      height: 615px;
    }
    @media (min-height: 915px) {
      height: 640px;
    }
    @media (min-height: 935px) {
      height: 575px;
    }
    @media (min-height: 960px) {
      height: 600px;
    }
    @media (min-height: 1010px) {
      height: 650px;
    }
    @media (min-height: 1060px) {
      height: 700px;
    }
    @media (min-height: 1110px) {
      height: 750px;
    }
  }

  @media (max-height: 934px) {
    .panel-header {
      padding-top: 26px;
      padding-bottom: 18px;
    }
    .panel-footer {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
`;

export const Nav = styled.div`
  padding: 20px 0;
  font-size: 14px;
  color: ${props => props.theme.grey} !important;

  @media (max-height: 934px) {
    padding: 10px 0;
  }

  > div > i {
    margin-left: 10px;
    margin-right: 9px;
    font-size: 13px;
  }

  a,
  a i {
    color: ${props => props.theme.grey} !important;
  }

  a i {
    font-size: 16px;
    margin-right: 0;
  }

  a[href]:hover,
  a[href]:hover i {
    color: ${props => props.theme.primary} !important;
  }

  .capitalize {
    text-transform: capitalize;
    display: inline;
  }
`;
