import styled from "styled-components";

export const Wrapper = styled.div`
  h3 {
    flex-grow: 0 !important;

    &.error {
      color: red;
    }

    &:first-child {
      margin-top: 10px;
    }
  }

  .input {
    margin-bottom: 21px;

    p {
      font-size: 10.4px;
    }
  }

  .react-datepicker-popper {
    min-width: 350px;
  }

  .rdw-editor-wrapper {
    display: inline-flex !important;
    align-self: stretch;
    flex-direction: column;
    min-height: 340px;
    border: 2px solid ${props => props.theme.light};
    border-radius: 3px;
  }
  h3.error + .rdw-editor-wrapper {
    border-color: red;
  }

  .rdw-editor-toolbar {
    border: none;
    border-bottom: 2px solid;
    border-color: inherit;
  }

  .rdw-editor-main {
    padding: 6px 28px;
    flex-grow: 1;
    cursor: text;
    overflow: hidden;

    span {
      font-weight: inherit;
    }
  }

  .rdw-option-wrapper {
    border: none;

    &:hover {
      background: ${props => props.theme.xxxLight};
      box-shadow: none;
    }

    &.rdw-option-active {
      background: ${props => props.theme.xLight};
      box-shadow: none;
    }
  }

  .public-DraftEditorPlaceholder-inner {
    color: ${props => props.theme.greyXXLight};
    font-weight: 300;
    letter-spacing: 0.4px;
  }

  ul.bullet {
    li {
      margin-top: 21px;
      list-style: none;
      list-style-position: outside;

      &:before {
        content: "•";
        font-size: 16px;
        color: #f29f5b;
        position: absolute;
        left: 40px;
      }
    }
  }
`;
