import logo from "./../../assets/imgs/icons/debriefer-icon.svg";
import React, { Component } from "react";
import { LogoImg } from "./style.js";

class Logo extends Component {
  render() {
    return <LogoImg src={logo} alt="home" />;
  }
}

export default Logo;
