import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import actions from "./actions";
import { userIdOverride } from "./../../modules/userOverride";

// Make shift schema for a new debrief. May be better to move this into its own file.
function getNewDebrief() {
  return {
    id: uuidv4(),
    createdAt: new Date(),
    action: {
      aim: "",
      target: "",
      measure: "",
      driveChange: "",
      whoWhatWhen: "",
    },
    status: "active",
    stage: 0,
    completed_stages: [false, false, false, false, false],
    location_id: "",
    meeting: {
      meetingDateTime: "",
      location: "",
      shift: "",
      numberInvitees: "",
      roleTypes: "",
      notes: "",
      keyTakeaways: "",
      tellingQuotes: "",
    },
    survey_questions: {
      all: [],
      needAssistance: [],
      team: [],
      you: [],
    },
    themes: {
      all: [],
      needAssistance: [],
      team: [],
      you: [],
    },
    custom_review_items: {
      needAssistance: [],
      team: [],
      you: [],
    },
    take_action: {
      progress: 0,
      completedDate: null,
      notes: "",
    },
  };
}

const loadAll = force => (dispatch, getState) => {
  const debrief = getState().debrief;
  // Don't let load run if already run or ran unless forced.
  if (!force && (debrief.isLoading || debrief.isLoaded)) {
    return;
  }
  dispatch(actions.clear());

  // Sets isLoading state in redux.
  dispatch(actions.loadAll());

  fetch(`/api/debriefs?status=active&userId=${userIdOverride}`, {
    credentials: "include",
    headers: { Pragma: "no-cache", "Cache-Control":"no-cache" },
  })
    .then(response => {
      if (response.status === 401) {
        throw Error("Unauthorized");
      }
      return response.json();
    })
    .then(data => {
      _.forEach(data, debrief => {
        if (debrief.take_action && debrief.take_action.progress < 100 && debrief.take_action.completedDate) {
          debrief.take_action.completedDate = null;
        }
      });
      // Sets all debriefs in redux.
      dispatch(actions.setAll(data));
    })
    .catch(e => {
      console.error("Parsing debriefs failed", e);
    });
};

const create = locationId => (dispatch, getState) => {
  // Cache current debriefs incase server 500 so we can revert data.
  const currentDebriefs = getState().debrief.debriefs.slice(0);

  const newDebriefRecord = {
    ...getNewDebrief(),
    location_id: locationId,
  }; 

  fetch(`/api/debriefs?status=active&userId=${userIdOverride}`, {
    method: "post",
    body: JSON.stringify(newDebriefRecord),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then(r => {
      console.log("Create successful");
    })
    .catch(e => {
      console.error("Create debrief failed", e);
      // Revert redux back to old record if backend update fails
      dispatch(actions.setAll(currentDebriefs));
    });

  // Add new debrief to redux without making the user wait for server to insert.
  dispatch(actions.add(newDebriefRecord));
};

const update = (debrief, updateDatabase) => (dispatch, getState) => {
  // Update redux and frontend without making user wait for server.
  dispatch(actions.update(debrief));

  if (updateDatabase) {
    // Cache current debrief incase update fails.
    const currentDebrief = { ...getState().debrief.currentDebrief };

    fetch(`/api/debriefs/${debrief.id}?userId=${userIdOverride}`, {
      method: "put",
      body: JSON.stringify(debrief),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(r => {

        if (r.ok) {
          console.log("Update successful");
        } else {
          
          if (r.status === 401) {
            window.location = '/sign-in';
          }
        }

      })
      .catch(e => {
        console.error("Updating debrief failed", e);
        // Revert redux back to old record if backend update fails
        dispatch(actions.update(currentDebrief));
      });
  }
};

const setCurrentId = Id => (dispatch, getState) => {
  const state = getState();
  
  if (!_.find(state.debrief.debriefs, d => d.id === Id)) {
    fetch('/api/debriefs/' + Id).then( r => r.json()).then( d => {
      dispatch(actions.setAll([...state.debrief.debriefs, ...d]));
      dispatch(actions.setCurrentId(Id));
    })
  } else {
    dispatch(actions.setCurrentId(Id));
  }

};

const toggleTheme = (theme, type) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };

  if (_.indexOf(newDebrief.themes[type], theme) === -1) {
    newDebrief.themes[type] = newDebrief.themes[type].concat(theme);
  } else {
    newDebrief.themes[type] = newDebrief.themes[type].filter(dTheme => dTheme !== theme);
  }

  dispatch(update(newDebrief));
};

const toggleQuestions = (questionId, type) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  const surveyQuestionIds = newDebrief.survey_questions[type];

  if (_.indexOf(surveyQuestionIds, questionId) === -1) {
    newDebrief.survey_questions[type] = surveyQuestionIds.concat(questionId);
  } else {
    newDebrief.survey_questions[type] = surveyQuestionIds.filter(dQuestionId => dQuestionId !== questionId);
  }

  dispatch(update(newDebrief));
};

const saveCustomItems = (customItems, group) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  newDebrief.custom_review_items[group] = customItems;

  dispatch(update(newDebrief));
};

const addCustomItem = (customItem, group) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  const customItems = newDebrief.custom_review_items[group];
  newDebrief.custom_review_items[group] = customItems.concat(customItem);

  dispatch(update(newDebrief, true));
};

const removeCustomItem = (customItem, group) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  const customItems = newDebrief.custom_review_items[group];

  newDebrief.custom_review_items[group] = customItems.filter(cI => cI !== customItem);

  dispatch(update(newDebrief, true));
};

const updateMeeting = (meeting, updateDatabase) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  newDebrief.meeting = { ...meeting };

  dispatch(update(newDebrief, updateDatabase));
};

const updateAction = action => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  newDebrief.action = { ...action };

  dispatch(update(newDebrief));
};

const updateTakeAction = (take_action, updateDatabase) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  newDebrief.take_action = { ...take_action };

  dispatch(update(newDebrief, updateDatabase));
};

const updateStage = (stage, enabled = true) => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  newDebrief.completed_stages[stage] = enabled;
  dispatch(update(newDebrief, true));
};

// Done from top level when current debrief is not set
const updateStatus = (debrief, status) => (dispatch, getState) => {
  const newDebrief = { ...debrief };
  newDebrief.status = status;

  // Updates redux and remote database.
  dispatch(update(newDebrief, true));
};

const save = () => (dispatch, getState) => {
  const newDebrief = { ...getState().debrief.currentDebrief };
  if (newDebrief.completed_stages.length === 4) {
    newDebrief.completed_stages[4] = false;
  }
  if (!newDebrief.take_action) {
    newDebrief.take_action = {
      progress: 0,
      completedDate: null,
      notes: "",
    };
  }

  // Updates redux and remote database.
  dispatch(update(newDebrief, true));
};

export default {
  loadAll,
  create,
  setCurrentId,
  toggleTheme,
  toggleQuestions,
  saveCustomItems,
  addCustomItem,
  removeCustomItem,
  updateMeeting,
  updateAction,
  updateTakeAction,
  updateStage,
  updateStatus,
  save,
};
