import _ from "lodash";

import React, { Component } from "react";
import { debriefOperations } from "./../../redux/debrief";
import Input from "./../Input";
import DatePicker from "./../DatePicker";
import { Grid } from "semantic-ui-react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class FormMeeting extends Component {
  constructor(props) {
    super(props);

    const currentForm = this.props.currentForm;

    // In order to make the date picker input happy when there is no date in the DB
    if (!currentForm.meetingDateTime || currentForm.meetingDateTime === "") {
      this.state = {
        ...currentForm,
        meetingDateTime: null,
      };
    } else {
      this.state = {
        ...currentForm,
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleKeyTakeawaysChange = this.handleKeyTakeawaysChange.bind(this);
    this.handleTellingQuotesChange = this.handleTellingQuotesChange.bind(this);
    this.getDateTimeInput = this.getDateTimeInput.bind(this);
    this.getLocationInput = this.getLocationInput.bind(this);
    this.getShiftInput = this.getShiftInput.bind(this);
  }

  handleChange(event) {
    let change = {};
    const { name, value, type } = event.target;

    if (name === 'meetingDateTime') {
      change.meetingDateTime = new Date(value);
      change.meetingDateTimeOffset = new Date().getTimezoneOffset();
      change.meetingDateTimeZone = new Date().toTimeString().match(/\((.+)\)/)[1];
    } else {
      change[name] = value;
    }

    // Make sure component state has fully updates before dispatching redux update
    this.setState(change, () => {
      this.props.dispatch(debriefOperations.updateMeeting(this.state, type === "date"));
    });
  }

  handleNotesChange(contentState) {
    this.handleChange({
      target: {
        name: "notes",
        value: contentState,
      },
    });
  }

  handleKeyTakeawaysChange(contentState) {
    this.handleChange({
      target: {
        name: "keyTakeaways",
        value: contentState,
      },
    });
  }

  handleTellingQuotesChange(contentState) {
    this.handleChange({
      target: {
        name: "tellingQuotes",
        value: contentState,
      },
    });
  }

  getDateTimeInput(options) {
    const { formDirty } = this.props;
    return (
      <DatePicker
        id="meetingDateTime"
        label={options.label && "Date and Time"}
        placeholder={options.placeholder && "Date and Time"}
        helperText="Choose a date and time"
        showTimeSelect
        onChange={this.handleChange}
        onBlur={this.props.save}
        disabled={this.props.disabled}
        value={this.state.meetingDateTime}
        error={!this.state.meetingDateTime && formDirty}
        fullWidth
        multiline
      />
    );
  }

  getLocationInput(options) {
    const { formDirty } = this.props;
    return (
      <Input
        id="location"
        label={options.label && "Location"}
        placeholder={options.placeholder && "Location"}
        helperText="Choose a meeting location"
        type="text"
        onChange={this.handleChange}
        onBlur={this.props.save}
        disabled={this.props.disabled}
        value={this.state.location}
        error={!this.state.location && formDirty}
        fullWidth
        multiline
      />
    );
  }

  getShiftInput(options) {
    const { formDirty } = this.props;
    return (
      <Input
        id="shift"
        label={options.label && "Shift"}
        placeholder={options.placeholder && "Shift"}
        helperText="Enter which shift you will be meeting with"
        type="text"
        onChange={this.handleChange}
        onBlur={this.props.save}
        disabled={this.props.disabled}
        value={this.state.shift}
        error={!this.state.shift && formDirty}
        fullWidth
        multiline
      />
    );
  }

  render() {
    const { formDirty } = this.props;
    switch (this.props.formType) {
      case "prepare": {
        return (
          <form>
            {this.getDateTimeInput({ placeholder: true })}
            {this.getLocationInput({ placeholder: true })}
            {this.getShiftInput({ placeholder: true })}
          </form>
        );
      }
      case "record": {
        return (
          <form>
            <h3>Confirm Meeting Details</h3>
            <Grid columns={5}>
              <Grid.Row>
                <Grid.Column>{this.getDateTimeInput({ label: true })}</Grid.Column>
                <Grid.Column>{this.getLocationInput({ label: true })}</Grid.Column>
                <Grid.Column>{this.getShiftInput({ label: true })}</Grid.Column>
                <Grid.Column>
                  <Input
                    id="numberAttended"
                    label="Number Attended"
                    helperText="How many individuals attended?"
                    type="number"
                    onChange={this.handleChange}
                    onBlur={this.props.save}
                    disabled={this.props.disabled}
                    value={this.state.numberAttended}
                    error={!this.state.numberAttended && formDirty}
                    fullWidth
                    multiline
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    id="roleTypes"
                    label="Role Types"
                    helperText="Enter the different role types that attended"
                    type="text"
                    onChange={this.handleChange}
                    onBlur={this.props.save}
                    disabled={this.props.disabled}
                    value={this.state.roleTypes}
                    error={!this.state.roleTypes && formDirty}
                    fullWidth
                    multiline
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid columns={2}>
              <Grid.Row stretched>
                <Grid.Column width={6}>
                  <h3
                    className={
                      formDirty && _.filter(this.state.notes.blocks, block => block.text).length < 1 ? "error" : ""
                    }
                  >
                    Record Meeting Notes
                  </h3>
                  <Editor
                    toolbar={{ options: ["inline", "list"], inline: { options: ["bold", "italic", "underline"] } }}
                    placeholder="Record and enter your meeting notes from the meeting here."
                    defaultContentState={this.state.notes}
                    onContentStateChange={this.handleNotesChange}
                    onBlur={this.props.save}
                  />
                </Grid.Column>
                <Grid.Column width={10}>
                  <h3
                    className={
                      formDirty && _.filter(this.state.keyTakeaways.blocks, block => block.text).length < 1
                        ? "error"
                        : ""
                    }
                  >
                    Key Takeaways
                  </h3>
                  <Editor
                    toolbar={{ options: ["inline", "list"], inline: { options: ["bold", "italic", "underline"] } }}
                    placeholder="Identify and summarize your key takeaways."
                    defaultContentState={this.state.keyTakeaways}
                    onContentStateChange={this.handleKeyTakeawaysChange}
                    onBlur={this.props.save}
                  />
                  <h3
                    className={
                      formDirty && _.filter(this.state.tellingQuotes.blocks, block => block.text).length < 1
                        ? "error"
                        : ""
                    }
                  >
                    Telling Quotes
                  </h3>
                  <Editor
                    toolbar={{ options: ["inline", "list"], inline: { options: ["bold", "italic", "underline"] } }}
                    placeholder="Enter telling quotes from the meeting."
                    defaultContentState={this.state.tellingQuotes}
                    onContentStateChange={this.handleTellingQuotesChange}
                    onBlur={this.props.save}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </form>
        );
      }
      default:
        return null;
    }
  }
}

export default FormMeeting;
